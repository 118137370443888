import axios from 'axios';
const nodeServerBaseUrl = process.env.REACT_APP_NODE_URL;

/**member profile */

export const getModelOnBroadcast = async (modelId) => {
   return (await axios.get(`ModelsProfile/${modelId}/GetBroadCastStatus`))?.data?.data;
};

export const updateModelBroadcastStatus = async ({ payload }) => {
   return (
      await axios.put(`ModelsProfile/ExitBroadCastUpdateByModel`, payload, {
         headers: { 'Content-Type': 'multipart/form-data' },
      })
   )?.data;
};

export const makeInitialBroadcastPayment = async ({ modelId, memberId, broadCastId }) => {
   return (
      await axios.get(
         `Payment/${modelId}/${memberId}/${broadCastId}/WalletBalOnlineB4JoiningLive`,

         {
            headers: { 'Content-Type': 'multipart/form-data' },
         }
      )
   )?.data;
};

export const getMemberMonthlySub = async (modelId) => {
   return (await axios.get(`MemberProfile/${modelId}/CheckMemberMonthlyFee`))?.data?.data;
};

export const getMemberProfile = async modelId => {
	return (await axios.get(`MemberProfile/${modelId}/GetMemberByMemberId`))?.data?.data;
};

export const getMemberBrowerHistory = async (modelId, pageNumber) => {
	return (await axios.get(`MemberProfile/${modelId}/MemberBrowserHistory?PageNumber=${pageNumber}`))?.data;
};

export const getMemberFavoriteModel = async (modelId, pageNumber) => {
	return (await axios.get(`MemberProfile/${modelId}/FavoriteMember?PageNumber=${pageNumber}`))?.data;
};

export const createPrivatChat = async data => {
	return axios.post(`${nodeServerBaseUrl}/api/v1/chat`, data);
};


export const deleteMemberAccount = async (memberid) => {
  return axios.delete(`MemberProfile/${memberid}/DeleteMemberByMemberId`);
};

/**premium models */
export const getPremiumModels = async () => {
  return (await axios.get(`/MemberProfile/GetAllModelOnPremiumService`))?.data;
};
