import Popup from 'reactjs-popup';
import styles from './privateRequestModal.module.scss';
import Button from '../../../custom/btn/Btn';
import CheckBox from '../../../custom/checkbox/CheckBox';
import { AiOutlineClose } from 'react-icons/ai';
import { useEffect, useState } from 'react';

const PrivateRequestModal = ({
  open,
  handleClose,
  handleEnablePrivateRequest,
  requestUser,
  handleDeclinePrivateRequest,
  handleAcceptPrivateRequest,
}) => {
  const [view, setView] = useState('initial');

  return (
    <Popup open={open} closeOnDocumentClick onClose={handleClose}>
      <>
        {view === 'initial' && (
          <InitialPopUp
            requestUser={requestUser}
            handleView={(currentView) => {
              setView(currentView);
            }}
          />
        )}

        {view === 'confirmation' && (
          <ConfirmationPopUp
            handleAcceptPrivateRequest={handleAcceptPrivateRequest}
            requestUser={requestUser}
            handleEnablePrivateRequest={handleEnablePrivateRequest}
            handleClose={() => {
              handleClose();
              setView('initial');
            }}
          />
        )}

        {view === 'decline' && (
          <DeclinePopUp
            handleDeclinePrivateRequest={handleDeclinePrivateRequest}
            requestUser={requestUser}
            handleClose={() => {
              handleClose();
              setView('initial');
            }}
          />
        )}
      </>
    </Popup>
  );
};

const DeclinePopUp = ({
  handleClose,
  requestUser,
  handleDeclinePrivateRequest,
}) => {
  return (
    <main className={styles.declineContainer}>
      <h1 className={styles.declineTitle}>Decline Private Call</h1>
      <p className={styles.declineSubTitle}>
        Are you sure you want to decline the call from{' '}
        <span>{requestUser}</span> ?
      </p>

      <Button
        onClick={() => {
          handleDeclinePrivateRequest();
          handleClose();
        }}
        text='Decline Call'
        className={styles.declineStartPrivateShowButton}
      />

      <Button
        onClick={() => {
          handleClose();
        }}
        text='Don’t Decline'
        className={styles.declineCancelPrivateShowButton}
      />
    </main>
  );
};

const ConfirmationPopUp = ({
  handleClose,
  handleEnablePrivateRequest,

  handleAcceptPrivateRequest,
}) => {
  return (
    <main className={styles.confirmationContainer}>
      <h1 className={styles.confirmationTitle}>Start Private Call</h1>
      <p className={styles.confirmationSubTitle}>
        You are currently running a broadcast, do you want to end the broadcast
        and start the Private call now?
      </p>
      <Button
        onClick={() => {
          handleAcceptPrivateRequest();
          handleEnablePrivateRequest();
          handleClose();
        }}
        text='Start Private Call'
        className={styles.confirmationStartPrivateShowButton}
      />

      <Button
        onClick={() => {
          handleClose();
        }}
        text='Don’t Start Yet'
        className={styles.confirmationCancelPrivateShowButton}
      />
    </main>
  );
};

const InitialPopUp = ({ handleView, requestUser }) => {
  return (
     <main className={styles.initialContainer}>
        <section>
           <h1 className={styles.initialTitle}>{requestUser}:</h1>
           {/* <p className={styles.initialSubTitle}>
          Paid <span>8 credits</span> for a private call.
        </p> */}
           <section className={styles.initialActionButtonContainer}>
              <Button
                 onClick={() => {
                    handleView('confirmation');
                 }}
                 text={'Start'}
                 className={styles.startPrivateRequestButton}
              />
              <Button
                 onClick={() => {
                    handleView('decline');
                 }}
                 text={'Decline'}
                 className={styles.declinePrivateRequestButton}
              />
           </section>
           <Button
              onClick={() => {
                 handleView('schedule');
              }}
              text={'Schedule Call'}
              className={styles.schedulePrivateRequestButton}
           />
        </section>
     </main>
  );
};

export default PrivateRequestModal;
