import axios from 'axios';





/**model streaming tip */

export const sendTipFromMember = async (payload) => {
  return (await axios.post(`ModelsProfile/LiveStreamingTipCreate`, payload))
    ?.data?.data;
};


export const createStreamingTip = async (payload) => {
  return (await axios.post(`ModelsProfile/SetupStreamingTipByModel`, payload))
    ?.data?.data;
};


export const getStreamingTips = async (modelId) => {
  return (
    (await axios.get(`ModelsProfile/${modelId}/SetupTipListStreaming`))?.data
      ?.data ?? []
  );
};

export const getStreamingTipFromMembers = async (modelId) => {
  return (
    (
      await axios.get(
        `ModelsProfile/${modelId}/ListLiveStreamingTipsFromMembers`
      )
    )?.data?.data ?? []
  );
};


export const updateStreamingTip = async (payload) => {
  return (await axios.put(`ModelsProfile/UpdateStreamingTip`, payload))?.data
    ?.data;
};

export const deleteStreamingTip = async (modelId, id) => {
  return (
    await axios.delete(`ModelsProfile/${modelId}/${id}/DeleteStreamingTip`)
  )?.data?.data;
  //DeleteLiveStreamingTip
};


/**model streaming gaol */

export const getStreamingGoals = async (modelId) => {
  return (await axios.get(`ModelsProfile/${modelId}/GetListOfGoalSetting`))
    ?.data?.data ?? [];
};

export const deleteStreamingGoal = async (modelId,id) => {
  return (await axios.delete(`ModelsProfile/${modelId}/${id}/DeleteGoalSettings`))?.data
    ?.data;
};

export const createStreamingGoal = async (payload) => {
  return (await axios.post(`ModelsProfile/GoalSettingCreate`, payload))?.data
    ?.data;
};

export const updateStreamingGoal = async (payload) => {
  return (await axios.put(`ModelsProfile/UpdateGoalSetting`, payload))?.data
    ?.data;
};

export const getModelPublicPageSettings = async modelId => {
	return (await axios.get(`ModelsProfile/${modelId}/GetPrivacySettingStatus`))?.data?.data;
};

export const updateModelPublicPageSetting = async payload => {
	return (await axios.put(`ModelsProfile/UpdatePublicPageStatus`, payload))?.data?.data;
};

export const updateModelCommentingSetting = async payload => {
	return (await axios.put(`ModelsProfile/UpdateCommentStatus`, payload))?.data?.data;
};

export const updateModelFanZoneSettings = async payload => {
	return (await axios.put(`ModelsProfile/UpdateFanZoneStatus`, payload))?.data?.data;
};

export const updateModelPhotoAndVideoSettings = async payload => {
	return (await axios.put(`ModelsProfile/UpdatePhotoStatus`, payload))?.data?.data;
};

export const updateModelStreamingSettings = async payload => {
	return (await axios.put(`ModelsProfile/UpdateStreamingStatus`, payload))?.data?.data;
};

/**model photos */

export const getModelPhotos = async modelId => {
	return (await axios.get(`ModelsProfile/${modelId}/GetListOfPhotosUploaded`))?.data?.data;
};

export const editModelPhotoName = async ({ payload }) => {
	return (await axios.put(`ModelsProfile/EditPhoto`, payload))?.data?.data;
};

export const editModelVideoName = async ({ payload }) => {
	return (await axios.put(`ModelsProfile`, payload))?.data?.data;
};

export const deleteModelPhoto = async ({ photoId, registeredId }) => {
	return (await axios.delete(`ModelsProfile/${registeredId}/${photoId}/DeleteModelPhotoOnly`))?.data?.data;
};

/**model block criteria */
export const createBlockCriteria = async ({ payload }) => {
	return (
		await axios({
			method: 'post',
			url: `ModelsProfile/BlockCriteria`,
			data: payload,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	)?.data;
};

export const updateBlockCriteria = async ({ payload }) => {
	return (
		await axios({
			method: 'put',
			url: `ModelsProfile/UpdateBlockCriteria`,
			data: payload,
		})
	)?.data;
};

export const deleteBlockCriteria = async ({ payload }) => {
	return (
		await axios({
			method: 'delete',
			url: `ModelsProfile/${payload.modelId}/${payload.id}/DeleteBlockCriteria`,
			data: payload,
		})
	)?.data;
};

export const getAllBlockCriteria = async ({ modelId }) => {
	return (
		await axios({
			method: 'get',
			url: `ModelsProfile/${modelId}/GetListofBlockByModel`,
		})
	)?.data;
};

/**model fan club */
export const createClub = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/AddFanClub`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getClub = async ({ modelId }) => {
  return (
    await axios({
      method: 'get',
      url: `ModelsProfile/${modelId}/GetFanClubByModelId`,
    })
  )?.data;
};

export const addFanClubPhoto = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/FanClubAddModelPhotos`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getFanClubPhoto = async ({ modelId }) => {
  return (
    await axios({
      method: 'get',
      url: `ModelsProfile/${modelId}/GetListOfFanClubPhotos`,
    })
  )?.data;
};

export const updateFanClubPhoto = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/FanClubEditPhoto`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const addToPhotoSets = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/AddFanClubPhotoToPhotoSet`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const removeFromPhotoSets = async ({ modelId, photoId }) => {
  return (
    await axios({
      method: 'delete',
      url: `ModelsProfile/${modelId}/${photoId}/RemovePhotoFromPhotoSets`,
    })
  )?.data;
};

export const updateFanClub = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/UpdateModelFanClub`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const createFanClubPhotoSet = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/CreateFanClubPhotoSet`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const updateFanClubPhotoSet = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/EditFanClubPhotoSet`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getFanClubPhotoSet = async ({ modelId }) => {
  return (await axios.get(`ModelsProfile/${modelId}/GetListOfPhotoSet`))?.data;
};

export const getFanClubPhotoSetPhotos = async ({ modelId, photoSetId }) => {
  return (
    await axios.get(
      `ModelsProfile/${modelId}/${photoSetId}GetFanClubPhotoAndPhotoSetAsync`
    )
  )?.data;
};

/**album */
export const createAlbum = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/CreateAlbum`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getAlbum = async ({ modelId }) => {
  return (await axios.get(`ModelsProfile/${modelId}/GetListOfAlbum`))?.data;
};

export const getAlbumPhoto = async ({ modelId, albumId }) => {
  return (
    await axios.get(`ModelsProfile/${modelId}/${albumId}GetPhotoAlbumByModelId`)
  )?.data;
};

export const updateAlbum = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/EditAlbum`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const addToAlbum = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/AddPhotoToAlbum`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

/**photos */
export const addPhoto = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/AddModelPhotos`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getPhoto = async ({ modelId }) => {
  return (
    await axios({
      method: 'get',
      url: `ModelsProfile/${modelId}/GetListOfPhotosUploaded`,
    })
  )?.data;
};

export const deletePhotos = async ({ modelId, photoId }) => {
  return (
    await axios({
      method: 'delete',
      url: `ModelsProfile/${modelId}/${photoId}/DeleteModelPhotoOnly`,
      headers: { 'Content-Type': 'application/json' },
    })
  )?.data;
};

export const updatePhoto = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/EditPhoto`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

/**model  video */
export const getVideos = async ({ modelId }) => {
  return (
    await axios.get(
      `ModelsProfile/${modelId}/GetVideoUploaded`
    )
  )?.data;
};

export const uploadVideo = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/UploadVideos`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const deleteModelVideo = async ({ modelId, videoId }) => {
  return (
    await axios({
      method: 'delete',
      url: `ModelsProfile/${modelId}/${videoId}/DeleteModelVideoOnly`,
    })
  )?.data;
};

// export const updateFanClubVideo = async ({ payload }) => {
//   return (
//     await axios({
//       method: 'put',
//       url: `ModelsProfile/FanClubEditVideoAsync`,
//       data: payload,
//       headers: { 'Content-Type': 'multipart/form-data' },
//     })
//   )?.data;
// };



/**model diary */
export const createDiary = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/CreateModelDiary`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const updateDiary = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/EditModelDiary`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const deleteDiary = async ({ modelId, diaryId }) => {
  return (
    await axios({
      method: 'delete',
      url: `ModelsProfile/${modelId}/${diaryId}/DeleteModelDiary`,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getDiaries = async ({ modelId }) => {
  return (await axios.get(`ModelsProfile/${modelId}/GetListOfModelDiary`))
    ?.data;
};

/**model fanClub video */
export const getFanClubVideos = async ({ modelId }) => {
  return (
    await axios.get(
      `ModelsProfile/${modelId}/GetMultipleFanClubVideosUploadedByModel`
    )
  )?.data;
};

export const uploadFanClubVideo = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/FanClubUploadVideos`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const deleteFanClubVideo = async ({ modelId, videoId }) => {
  return (
    await axios({
      method: 'delete',
      url: `ModelsProfile/${modelId}/${videoId}/DeleteFanClubVideosAsync`,
    })
  )?.data;
};

export const updateFanClubVideo = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/FanClubEditVideoAsync`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

/**model fanClub shows */
export const uploadFanClubShow = async ({ payload }) => {
  return (
    await axios({
      method: 'post',
      url: `ModelsProfile/CreateModelShow`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getFanClubShows = async ({ modelId }) => {
  return (await axios.get(`ModelsProfile/${modelId}/GetModelShowByIdAsync`))
    ?.data;
};

export const deleteFanClubShow = async ({ modelId, showId }) => {
  return (
    await axios({
      method: 'delete',
      url: `ModelsProfile/${modelId}/${showId}/DeleteModelShow`,
    })
  )?.data;
};

export const updateFanClubShow = async ({ payload }) => {
  return (
    await axios({
      method: 'put',
      url: `ModelsProfile/EditModelShow`,
      data: payload,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

/**model appointment */
export const addAppointment = async ({ payload }) => {
	return (
		await axios({
			method: 'post',
			url: `ModelsProfile/CreateAppointment`,
			data: payload,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	)?.data;
};

export const getAppointments = async ({ user_id }) => {
	return (await axios.get(`ModelsProfile/${user_id}/GetListOfModelAppoint`))?.data;
};

export const deleteAppointment = async ({ user_id, id }) => {
	return (await axios.delete(`ModelsProfile/${user_id}/${id}/DeleteModelAppointment`))?.data;
};

export const updateAppointment = async ({ payload }) => {
	return (
		await axios.put(`ModelsProfile/UpdateModelAppointment`, JSON.stringify(payload), {
			headers: { 'Content-Type': 'application/json' },
		})
	)?.data;
};

/**model event */
export const createEvent = async ({ payload }) => {
	return (
		await axios({
			method: 'post',
			url: `ModelsProfile/AddModelEventDateTime`,
			data: payload,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	)?.data;
};

export const deleteEvent = async ({ payload }) => {
	return (
		await axios({
			method: 'delete',
			url: `ModelsProfile/${payload.modelId}/${payload.id}/DeleteModelEvent`,
		})
	)?.data;
};

export const saveEvent = async ({ payload }) => {
	return (
		await axios.put(`ModelsProfile/EditModelEventDateTime`, payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	)?.data;
};

export const getEvents = async ({ user_id }) => {
	return (await axios.get(`ModelsProfile/${user_id}/GetListOfModelEvents`))?.data;
};

export const deleteModelPhotos = async ({ photoIds, registeredId }) => {
	return (await axios.delete(`ModelsProfile/${registeredId}/${photoIds}/DeleteMultiplePhotoOnly`))?.data?.data;
};

export const uploadModelPhotos = async data => (await axios.post('/ModelsProfile/AddModelPhotos', data))?.data;

export const updateMultiplePhotosPermission = async data =>
	(await axios.put('/ModelsProfile/PermissionMultiplePhotoOnly', data))?.data;




/**model broadcasting */


export const disableMemberChat = async ({ payload }) => {
  return (
    await axios.post(`ModelsProfile/DisableMemberFromStreaming`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};

export const getListOfStreamMemberDisabled = async ({ modelId, memberId }) => {
  return (
    await axios.get(`ModelsProfile/${modelId}/GetListOfDisableMemberFromStr`)
  )?.data?.data;
};

export const getStreamMemberDisabled = async ({ modelId, memberId }) => {
  return (
    await axios.get(
      `ModelsProfile/${modelId}/${memberId}/DisableMemberByModelFromStreaming`
    )
  )?.data?.data;
};

export const getStreamingConfig = async ({ modelId   }) => {
	return (
    await axios.get(`ModelsProfile/${modelId}/GetModelBroadcastSettings`)
  )?.data?.data;
};

export const updateStreamingBroadcastConfig = async ({payload }) => {
  return (
    await axios.put(`ModelsProfile/UpdateStreamingBroadcastModel`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};


export const updateStreamingBroadcastSetting = async ({ payload }) => {
  return (
    await axios.put(`ModelsProfile/UpdateBroadcastSettingsModel`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};


export const getBroadcastGoal = async ({ modelId }) => {
  return (
    await axios.get(`ModelsProfile/${modelId}/GetListOfGoalSetting`, {
      headers: { 'Content-Type': 'application/json' },
    })
  )?.data?.data;
};

export const createBroadcastGoal = async ({ payload }) => {
  return (
    await axios.post(`ModelsProfile/GoalSettingCreate`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  )?.data;
};


export const modelBroadcastOfflineAndOnlineStatus = async ({ payload }) => {
   return (
      await axios.put(`ModelsProfile/UpdateOnlineBroCastModel`, payload, {
         headers: { 'Content-Type': 'multipart/form-data' },
      })
   )?.data;
};



/**model payout */

export const getPayeeModelDetails = async ({ modelId }) => {
   return (await axios.get(`Payment/${modelId}/GetPayeeInformation`))?.data?.data;
};

export const createPayeeInformation = async ({ payload }) => {
   return (await axios.post(`Payment/AddPayeeInformation`, payload))?.data?.data;
};

export const updatePayeeInformation = async ({ payload }) => {
   return (await axios.put(`Payment/UpdatePayeeInformation`, payload))?.data?.data;
};

export const getModelPayouts = async ({ pageNumber, modelId, startDate, endDate }) => {
   return (
      await axios.get(
         `Payment/${modelId}/GetModelPayoutDashboard?PageNumber=${pageNumber}&pageSize=20&startDate=${startDate}&endDate=${endDate}&registerId=${modelId}`
      )
   )?.data?.data;
};

export const getModelPaymentInfo = async (modelId) => {
   return (await axios.get(`Payment/${modelId}/GetBanksSetupByModel`))?.data?.data;
};

export const requestPayout = async (payload) => {
   return (await axios.post(`Payment/RequestForPayoutFunds`, payload))?.data?.data;
};

export const confirmPayoutOtp = async (payload) => {
   return (await axios.post(`Payment/verifyPayoutRequest`, payload))?.data?.data;
};

/**model prices */

export const getModelPrices = async (modelId) => {
   return (await axios.get(`ModelsProfile/${modelId}/GetPriceSetUp`))?.data?.data;
};

export const updateModelPrivateChatPrice = async (payload) => {
   return (await axios.put(`ModelsProfile/UpdateRequestPChatTariff`, payload))?.data?.data;
};

export const updateModelPrivateCallAndBroadcastPrices = async (payload) => {
   return (await axios.put(`ModelsProfile/UpdateModelTariff`, payload))?.data?.data;
};

/**model transactions */

export const getModelTransactions = async ({ pageNumber, modelId, startDate, endDate }) => {
   return (
      await axios.get(
         `Payment/${modelId}/GetModelTransactions?PageNumber=${pageNumber}&pageSize=20&startDate=${startDate}&endDate=${endDate}&registerId=${modelId}`
      )
   )?.data?.data;
};

/**model stats */

export const getModelStats = async ({ pageNumber, modelId, startDate, endDate }) => {
   return (
      await axios.get(
         `Payment/${modelId}/GetAllQuickStatsTransactionAsync?PageNumber=${pageNumber}&PageSize=20&startDate=${startDate}&endDate=${endDate}&registerId=${modelId}`
      )
   )?.data?.data;
};

/**model details */

export const getModelDetails = async ({ modelId }) => {
   return (await axios.get(`/ModelsProfile/${modelId}/GetModelById`))?.data?.data;
};

export const updateModelDetails = async ({ payload, modelId }) => {
   return (await axios.put(`/ModelsProfile/${modelId}/UpdateModelDetails`, payload))?.data?.data;
};

export const updateModelDefaultMemberBroadcastJoiningAmount = async ({ payload }) => {
   return (
      await axios.put(`/ModelsProfile/UpOnlineBroCastCostFromModel`, payload, {
         headers: { 'Content-Type': 'multipart/form-data' },
      })
   )?.data?.data;
};

export const changePassword = async ({ payload, modelId }) => {
  return (
    await axios.post(`/Authentication/${modelId}/change-password`, payload)
  )?.data?.data;
};


/**model public comments */
export const getPublicComment = async ({modelId}) => {
  return (await axios.get(`/ModelsProfile/${modelId}/GetCommentDetailByIDViewAsync`))
    ?.data;
};

export const addModelComment = async ({ payload }) => {
  return (
    await axios.post(`/ModelsProfile/AddModelComments`, payload)
  )?.data?.data;
};

export const getCommentLike = async ({
  modelId,
  commentId,
  userLike,
  memberId,
}) => {
  return (
    await axios.get(
      `LikeEndPoint/${modelId}/${memberId}/${commentId}/ GetModelCommentLikes`
    )
  )?.data;
};

export const postCommentLike = async ({
  modelId,
  commentId,
  userLike,
  memberId,
}) => {
  return (
    await axios.post(
      `LikeEndPoint/${modelId}/${memberId}/${userLike}/${commentId}/CommentLikes`
    )
  )?.data;
};

