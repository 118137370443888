import { Route, Switch } from 'react-router-dom';
import Header from './views/header/Header';
import Footer from './views/footer/Footer';
import Main from './views/main/Main';
import Detail from './views/detail/Detail';
import Category from './views/category/Category';
import CategoryDetail from './views/category/Detail';
import Agecheck from './views/agecheck/Agecheck';
import { ReactNotifications } from 'react-notifications-component';

import ProtectedRoute from './views/auth/ProtectedRoute';
import { MEMBER, CLUB, MODEL } from '../src/const/Const';

import Search from './views/search/Search';
import NotFound from './views/search/NotFound';

import NoMatch from './views/NoMatch/NoMatch';
import TopModels from './views/topModels/TopModels';
import NewModels from './views/newModels/NewModels';

import Privacy from './views/privacy/Privacy';
import TermsAndConditions from './views/terms&conditions/TermsAndConditions';

import ForgetPassword from './views/forget/ForgetPassword';
import ResetPassword from './views/forget/ResetPassword';

import RegistrationMember from './views/registration/RegistrationMember';
import RegistrationModel from './views/registration/RegistrationModel';
import RegistrationClub from './views/registration/RegistrationClub';
import Confirm from './views/confirm/Confirm';

import RegistrationModelInfo from './views/registrationmodel/RegistrationModelInformation';

import Club from './views/club/AllClubView/Club';
import ClubAnalysis from './views/club/ClubAnalysis';
import ClubProfile from './views/club/profile/ClubProfile';
import ClubDetail from './views/club/profile/publicProfile/Detail';
import ClubLkInfo from './views/lkclub/LkInfo';
import ClubUploadPicture from './views/clubUploadPicture/clubUploadPicture';
import LkClub from './views/lkclub/LkClub';
import UnverifiedClub from './views/UnverifiedClub/UnverifiedClub';

import LkUser from './views/lkmember/Lkuser';
import LkEdit from './views/lkmember/LkEdit';
import LkHistory from './views/lkmember/LkHistory';
import LkMessage from './views/lkmember/LkMessage';
import LkFavourite from './views/lkmember/LkFavourite';
import Payment from './views/payment/Payment';
import ContactUs from './views/contactUs/ContactUs';
import ModelUploadPicture from './views/modelUploadPicture/ModelUploadPicture';
import ModelProfile from './views/modelProfile/ModelProfile';
import ModelPublicProfile from './views/modelPublicProfile/ModelPublicProfile';
import UnverifiedModel from './views/UnverifiedModel/UnverifiedModel';
import ModelProfilePhoto from './views/model-photo/ModelPhoto';
import ModelVideo from './views/model-video/ModelVideo';
import ModelStatistics from './views/modelStats/ModelStats';
import Transactions from './views/Transactions/Transactions';
import FanClub from './views/fanClub/FanClub';
import Payout from './views/Payout/Payout';
import ModelPrice from './views/ModelPrice/ModelPrice';
import BlockerUser from './views/blockUsers/BlockUser';
import FanClubEvent from './views/fanclubEvents/FanClubEvent';
import ModelFanClubPhotoSets from './views/modelFanClubPhotoSets/ModelFanClubPhotoSets';
import ModelFanClubStore from './views/modelFanClubStore/ModelFanClubStore';
import ModelAppointments from './views/modelAppointment/ModelAppointments';
import ModelFanClubDairy from './views/modelFanClubDairy/ModelFanClubDairy';
import ModelFanClubVideo from './views/modelFanClubVideo/ModelFanClubVideo';
import ModelFanClubShows from './views/modelFanClubShow/ModelFanClubShow';
import RegistrationInformation from './views/registrationInformation/RegistrationInformation';
import ModelPersonalInformation from './views/modelPersonalInformation/ModelPersonalInformation';
import ModelAlbum from './views/modelAlbum/ModelAlbum';
import ModelLiveStream from './views/ModelLiveStreaming/ModelLiveStreaming';
import ModelPayeeInformation from './views/modelPayeeInformation/ModelPayeeInformation';
import BroadcastSetting from './views/ModelLiveStreaming/fullBroadcastSetting/BroadcastSetting';
import CommentFeed from './views/club/profile/publicProfile/CommentFeed';
import PaymentSuccess from './views/paymentSuccess/PaymentSuccess';
import PaymentError from './views/paymentError/PaymentError';

import { OVER_18 } from './views/agecheck/Over';

const App = () => {
	if (OVER_18 === 'over18') {
		return (
			<div className='app'>
				<ReactNotifications />
				<Header />
				<Switch>
					<Route exact path='/' component={Main} />
					<ProtectedRoute roles={[MEMBER, MODEL, CLUB]} path='/payment-success' component={PaymentSuccess} />
					<ProtectedRoute roles={[MEMBER, MODEL, CLUB]} path='/payment-error' component={PaymentError} />
					<ProtectedRoute roles={[MEMBER, MODEL, CLUB]} path='/detail/about' component={Detail} />
					<ProtectedRoute roles={[MEMBER, MODEL, CLUB]} path='/detail/photos' component={Detail} />
					<ProtectedRoute roles={[MEMBER, MODEL, CLUB]} path='/detail/comments' component={Detail} />
					<ProtectedRoute roles={[MEMBER, MODEL, CLUB]} path='/detail/fanclub' component={Detail} />
					<Route path='/category/:category' component={CategoryDetail} />
					<Route path='/category' component={Category} />
					<Route path='/search' component={Search} />
					<Route path='/notfound' component={NotFound} />
					<Route path='/contact-us' component={ContactUs} />
					<Route path='/top-models' component={TopModels} />
					<Route path='/new-models' component={NewModels} />
					<Route path='/privacy-policy' component={Privacy} />
					<Route path='/terms-and-conditions' component={TermsAndConditions} />
					<Route path='/lost_password' component={ForgetPassword} />
					<Route path='/reset_password' component={ResetPassword} />
					<Route path='/registration-member' component={RegistrationMember} />
					<Route path='/registration-model' component={RegistrationModel} />
					<Route path='/registration-club' component={RegistrationClub} />
					<Route path='/confirm_email' component={Confirm} />
					<Route path='/clubs' component={Club} />
					<Route path='/club/post/comment' component={CommentFeed} />
					<Route path='/club-profile' component={ClubProfile} />
					<Route path='/clubs-detail' component={ClubDetail} />
					<Route path='/clubs-analysis' component={ClubAnalysis} />
					<Route path='/clubs-detail-info' component={ClubLkInfo} />
					<Route path='/lk-club' component={LkClub} />
					<Route path='/lk' component={LkUser} />
					<ProtectedRoute roles={[MEMBER]} path='/lk-edit' component={LkEdit} />
					<Route path='/lk-favourite' component={LkFavourite} />
					<Route path='/lk-history' component={LkHistory} />
					<Route path='/lk-message' component={LkMessage} />
					<ProtectedRoute roles={[MODEL]} path='/mode-live-streaming' component={ModelLiveStream} />
					<ProtectedRoute roles={[MODEL]} path='/registration-model-info' component={RegistrationModelInfo} />
					<ProtectedRoute roles={[MODEL]} path='/model-upload-picture' component={ModelUploadPicture} />
					<ProtectedRoute roles={[MODEL]} path='/model-profile' component={ModelProfile} />
					<ProtectedRoute roles={[MODEL]} path='/model-profile-photo' component={ModelProfilePhoto} />
					<ProtectedRoute roles={[MODEL]} path='/model-video' component={ModelVideo} />
					<ProtectedRoute roles={[MODEL]} path='/model-public-profile' component={ModelPublicProfile} />
					<ProtectedRoute roles={[MODEL]} path='/model-stats' component={ModelStatistics} />
					<ProtectedRoute roles={[MODEL]} path='/transactions' component={Transactions} />
					<ProtectedRoute roles={[MODEL]} path='/fan-club' component={FanClub} />
					<ProtectedRoute roles={[MODEL]} path='/fan-club-store' component={ModelFanClubStore} />
					<ProtectedRoute roles={[MODEL]} path='/fan-club-photo-sets' component={ModelFanClubPhotoSets} />
					<ProtectedRoute roles={[MODEL]} path='/fan-club-dairies' component={ModelFanClubDairy} />
					<ProtectedRoute roles={[MODEL]} path='/fan-club-videos' component={ModelFanClubVideo} />
					<ProtectedRoute roles={[MODEL]} path='/fan-club-shows' component={ModelFanClubShows} />
					<ProtectedRoute roles={[MODEL]} path='/fan-club-event' component={FanClubEvent} />
					<ProtectedRoute roles={[MODEL]} path='/model-price' component={ModelPrice} />
					<ProtectedRoute roles={[MODEL]} path='/block-users' component={BlockerUser} />
					<ProtectedRoute roles={[MODEL]} path='/model-appointments' component={ModelAppointments} />
					<ProtectedRoute roles={[MODEL]} path='/registration-information' component={RegistrationInformation} />
					<ProtectedRoute roles={[MODEL]} path='/personal-information' component={ModelPersonalInformation} />
					<ProtectedRoute roles={[MODEL]} path='/payee-information' component={ModelPayeeInformation} />
					<ProtectedRoute roles={[MODEL]} path='/broadcast-setting' component={BroadcastSetting} />
					<ProtectedRoute roles={[MODEL]} path='/model-album' component={ModelAlbum} />
					<ProtectedRoute roles={[MODEL]} path='/payouts' component={Payout} />
					<Route roles={[MODEL]} path='/unverified-model' component={UnverifiedModel} />
					<Route roles={[CLUB]} path='/unverified-club' component={UnverifiedClub} />
					<ProtectedRoute roles={[CLUB]} path='/club-upload-picture' component={ClubUploadPicture} />
					<Route path='/payment' component={Payment} />
					<Route path='*' component={NoMatch} />
				</Switch>
				<Footer />
			</div>
		);
	} else {
		return (
			<div className='app'>
				<Agecheck />
			</div>
		);
	}
};

export default App;
