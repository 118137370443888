import React, { useState } from 'react';
import FluidContainer from '../../custom/fluidContainer/FluidContainer';
import ModelProfileHeader from '../../custom/modelProfileHeader/ModelProfileHeader';
import styles from './modelPublicProfile.module.scss';
import CheckBox from '../../custom/checkbox/CheckBox';
import { useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
	getModelPublicPageSettings,
	updateModelPublicPageSetting,
	updateModelCommentingSetting,
	updateModelFanZoneSettings,
	updateModelPhotoAndVideoSettings,
	updateModelStreamingSettings,
} from '../../request/model';
import Spinner from '../../custom/spinner/Spinner';

const ModelPublicProfile = () => {
	const [publicProfileSetting, setPublicProfileSetting] = useState({
		Comment: false,
		FanZone: false,
		Photo: false,
		PublicPage: false,
		Streaming: false,
	});
	const clientId = useSelector(state => state?.user?.clientId);
	const queryClient = useQueryClient();
	const { isLoading, error, isError } = useQuery(
		{
			queryKey: ['getModelProfileSettings'],
			queryFn: () => getModelPublicPageSettings(clientId),
			onSuccess: data => {
				setPublicProfileSetting({
					Comment: data?.commenting,
					FanZone: data?.fanzone,
					Photo: data?.photandVideo,
					PublicPage: data?.publicPage,
					Streaming: data?.streaming,
				});
			},
		},
		{
			enabled: !!clientId,
		}
	);

	const publicPageMutation = useMutation(['updatePublicPage'], payload => updateModelPublicPageSetting(payload));

	const streamingMutation = useMutation(['updatePublicPage'], payload => updateModelStreamingSettings(payload));

	const commentingMutation = useMutation(['updatePublicPage'], payload => updateModelCommentingSetting(payload));

	const fanzoneMutation = useMutation(['updatePublicPage'], payload => updateModelFanZoneSettings(payload));

	const photandVideoMutation = useMutation(['updatePublicPage'], payload => updateModelPhotoAndVideoSettings(payload));

	const mutation = {
		PublicPage: publicPageMutation,
		Streaming: streamingMutation,
		Comment: commentingMutation,
		FanZone: fanzoneMutation,
		Photo: photandVideoMutation,
	};

	const handlePublicProfileSettings = e => {
		const { checked, name } = e.target;

		const updateMutation = mutation[name];

		if (typeof updateMutation !== 'object') return;

		const formData = new FormData();
		formData.append(name, checked);
		formData.append('ModelId', clientId);

		updateMutation?.mutate(formData, {
			onSuccess: () => {
				queryClient.refetchQueries(['getModelProfileSettings']);
			},

			onError: error => {
				console.log(error?.response?.data?.message ?? error?.message);
			},
		});
	};

	return (
      <FluidContainer>
         <ModelProfileHeader />
         {isLoading ? (
            <Spinner wrapperStyle={{ justifyContent: 'center' }} width={40} height={40} />
         ) : (
            <>
               <section className={styles.pageTitleContainer}>
                  <div className={styles.wrapper}>
                     <CheckBox
                        type='checkbox'
                        checked={publicProfileSetting?.PublicPage}
                        onChange={handlePublicProfileSettings}
                        name='PublicPage'
                        loading={true}
                     />
                     <div>
                        <h4 className={styles.heading}>Public page</h4>
                        <p className={styles.headingPara}>
                           Enable this option if you want to hide/unhide your profile for public{' '}
                           <br /> access and search
                        </p>
                     </div>
                  </div>
               </section>
               <section className={styles.settingContainer}>
                  {/* <div className={styles.wrapper}>
							<CheckBox
								type='checkbox'
								checked={publicProfileSetting?.Streaming}
								name='Streaming'
								onChange={handlePublicProfileSettings}
							/>
							<div>
								<h4 className={styles.subHeading}>Streaming</h4>
								<p className={styles.para}>
									Turn on this option if you do plan to conduct video streams. The ability to sell photo and
									video content will remain
								</p>
							</div>
						</div> */}
                  <div className={styles.wrapper}>
                     <CheckBox
                        type='checkbox'
                        checked={publicProfileSetting?.Photo}
                        name='Photo'
                        onChange={handlePublicProfileSettings}
                     />
                     <div>
                        <h4 className={styles.subHeading}>Photo & video</h4>
                        <p className={styles.para}>
                           Enable this option if you do want a section with photos and videos to be
                           displayed on your public page{' '}
                        </p>
                     </div>
                  </div>
                  <div className={styles.wrapper}>
                     <CheckBox
                        type='checkbox'
                        checked={publicProfileSetting?.Comment}
                        name='Comment'
                        onChange={handlePublicProfileSettings}
                     />
                     <div>
                        <h4 className={styles.subHeading}>Comments</h4>
                        <p className={styles.para}>
                           Turn on this option if you want it to be impossible to leave comments on
                           your public page. The menu item on the public page will be hidden
                        </p>
                     </div>
                  </div>
                  {/* <div className={styles.wrapper}>
                     <CheckBox
                        type='checkbox'
                        checked={publicProfileSetting?.FanZone}
                        name='FanZone'
                        onChange={handlePublicProfileSettings}
                     />
                     <div>
                        <h4 className={styles.subHeading}>FanZone</h4>
                        <p className={styles.para}>
                           Turn on disable this option if you want the FanZone section to be
                           displayed on your public page{' '}
                        </p>
                     </div>
                  </div> */}
               </section>
            </>
         )}
      </FluidContainer>
   );
};

export default ModelPublicProfile;
