import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MEMBER, MODEL, CLUB } from '../../const/Const';
import classNames from 'classnames';
import styles from './styles.module.scss';

const PaymentError = () => {
	const [profileLink, setProfileLink] = useState('');
	const { userType } = useSelector(state => state?.user);
	// generate profile link
	useEffect(() => {
		switch (userType?.toLowerCase()) {
			case MEMBER:
				return setProfileLink('/lk');
			case CLUB:
				return setProfileLink('/clubs-detail-info');
			case MODEL:
				return setProfileLink('/model-profile');
			default:
		}
	}, [userType]);

	return (
		<section className={classNames('container', styles.section)}>
			<svg
				width='64'
				height='64'
				viewBox='0 0 64 64'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className={styles.paymentIcon}
			>
				<circle cx='32' cy='32' r='32' fill='url(#paint0_linear_12206_290537)' />
				<g style={{ mixBlendMode: 'overlay' }}>
					<path
						d='M35.9766 28.0238L37.6566 26.3438'
						stroke='white'
						stroke-width='3'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M26.3438 37.6562L31.8438 32.1562'
						stroke='white'
						stroke-width='3'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M37.6637 37.6637L26.3438 26.3438'
						stroke='white'
						stroke-width='3'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M16 20C13.5 23.34 12 27.5 12 32C12 43.04 20.96 52 32 52C43.04 52 52 43.04 52 32C52 20.96 43.04 12 32 12C29.14 12 26.4 12.6 23.94 13.7'
						stroke='white'
						stroke-width='3'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</g>
				<defs>
					<linearGradient
						id='paint0_linear_12206_290537'
						x1='-3.94881e-07'
						y1='32.0001'
						x2='64'
						y2='32.0001'
						gradientUnits='userSpaceOnUse'
					>
						<stop stop-color='#413450' />
						<stop offset='0.750097' stop-color='#563143' />
					</linearGradient>
				</defs>
			</svg>

			<h1 className={styles.heading}>Payment Failed</h1>
			<p className={styles.para}>Payment failed. Please try again or choose another method of payment. </p>
			<div className={styles.wrapper}>
				<Link to={profileLink} className={styles.link}>
					Return to dashboard
				</Link>
			</div>
		</section>
	);
};

export default PaymentError;
