import React, { useEffect, useState, useRef } from 'react';
import logo from './logo.svg';
import logoM from './logoM.svg';
import Category from './category/Category';
import MemberAuth from '../popup/MemberAuth';
import { NavLink, Link } from 'react-router-dom';
import { WINDOW_WIDTH, MOBILE } from '../../const/Const';
import FormSubscribe from '../components/subscribe/Subscribe';
import { MEMBER, MODEL, CLUB } from '../../const/Const';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/useActionCreators';
const Header = () => {
	const [profileLink, setProfileLink] = useState('');
	const jsHamburger = useRef(null);
	const asideMenu = useRef(null);
	const asideMenuClose = useRef(null);
	const { token, userType } = useSelector(state => state?.user);

	const { logOut } = useActionCreator();

	// generate profile link
	useEffect(() => {
		switch (userType?.toLowerCase()) {
			case MEMBER:
				return setProfileLink('/lk');
			case CLUB:
				return setProfileLink('/clubs-detail-info');
			case MODEL:
				return setProfileLink('/model-profile');
			default:
		}
	}, [userType]);

	const handleLogout = () => {
		logOut();
	};

	const handleHamburger = () => {
		jsHamburger.current.classList.toggle('is-active');
		asideMenu.current.classList.toggle('show');
	};

	const hanldeClickOnAsideMenu = () => {
		jsHamburger.current.classList.toggle('is-active');
		asideMenu.current.classList.toggle('show');
	};

	if (WINDOW_WIDTH <= MOBILE) {
		return (
			<header className='header-mobile'>
				<div className='container'>
					<div className='header-mobile-menu' onClick={handleHamburger} ref={jsHamburger}>
						<div className='hamburger hamburger--squeeze js-hamburger'>
							<div className='hamburger-box'>
								<div className='hamburger-inner' />
							</div>
						</div>
						MENU
					</div>
					<Link to='/' className='header-mobile--logo'>
						<img src={logoM} alt='nsa1-logo' />
					</Link>
				</div>
				<aside className='aside-menu' ref={asideMenu}>
					<div className='aside-menu--inner'>
						{token && <MemberAuth />}
						<ul className='header-menu'>
							<li className='header-menu-item'>
								<NavLink
									className='header-menu--link'
									activeClassName='header-menu--link__active'
									to='/new-models'
								>
									New models
								</NavLink>
							</li>
							<li className='header-menu-item'>
								<NavLink
									className='header-menu--link'
									activeClassName='header-menu--link__active'
									to='/top-models'
								>
									TOP Models
								</NavLink>
							</li>
							<li className='header-menu-item'>
								<NavLink
									className='header-menu--link'
									activeClassName='header-menu--link__active'
									to='/category'
								>
									Categories
								</NavLink>
							</li>
							<li className='header-menu-item'>
								<NavLink className='header-menu--link' activeClassName='header-menu--link__active' to='/clubs'>
									Clubs
								</NavLink>
							</li>
						</ul>
						<div className='footer-mobile--title2'>
							Subscribe <br />
							to our newsletter
						</div>
						<FormSubscribe />
						<div className='footer-mobile--title3'>Follow us now!</div>
						<ul className='footer-subscribe--links'>
							<li>
								<a href='https://www.google.com/' target='_blank' rel='noopener noreferrer'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='13'
										height='29'
										viewBox='0 0 13 29'
										fill='none'
									>
										<path
											d='M0.415083 15.4454H3.23607V28.5323C3.23607 28.7906 3.42186 29 3.65116 29H8.43425C8.66354 29 8.84933 28.7906 8.84933 28.5323V15.507H12.0923C12.3032 15.507 12.4806 15.3287 12.5046 15.0927L12.9972 10.2748C13.0107 10.1422 12.9734 10.0095 12.8947 9.91005C12.816 9.81051 12.7032 9.75354 12.5849 9.75354H8.8495V6.73343C8.8495 5.82301 9.2845 5.36135 10.1426 5.36135C10.2648 5.36135 12.5849 5.36135 12.5849 5.36135C12.8142 5.36135 13 5.1519 13 4.89361V0.471203C13 0.212823 12.8142 0.00346129 12.5849 0.00346129H9.219C9.19526 0.00215161 9.14255 0 9.06484 0C8.48082 0 6.45081 0.12919 4.84726 1.79155C3.07054 3.6337 3.31751 5.83938 3.37654 6.22181V9.75345H0.415083C0.185791 9.75345 0 9.96281 0 10.2212V14.9776C0 15.2359 0.185791 15.4454 0.415083 15.4454Z'
											fill='#DADADA'
										/>
									</svg>
								</a>
							</li>
							<li>
								<a href='https://www.google.com/' target='_blank' rel='noopener noreferrer'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='28'
										height='24'
										viewBox='0 0 28 24'
										fill='none'
									>
										<path
											d='M27.3654 2.76977C26.9255 2.97402 26.4733 3.1464 26.0112 3.28635C26.5583 2.63861 26.9754 1.87646 27.23 1.04243C27.2871 0.855488 27.2279 0.650859 27.0812 0.528593C26.9346 0.406232 26.7309 0.391575 26.5695 0.491714C25.5881 1.10106 24.5294 1.53897 23.4192 1.79504C22.301 0.651049 20.7821 0 19.2119 0C15.8976 0 13.2011 2.82291 13.2011 6.29271C13.2011 6.56599 13.2176 6.83776 13.2503 7.10574C9.13747 6.72769 5.31386 4.61133 2.68819 1.2394C2.59462 1.11922 2.45046 1.05444 2.30288 1.06683C2.1552 1.07893 2.02252 1.16612 1.94791 1.30011C1.41537 2.25678 1.13383 3.35094 1.13383 4.4642C1.13383 5.98047 1.65093 7.41912 2.56436 8.54325C2.28662 8.44254 2.0171 8.31668 1.75986 8.16718C1.62176 8.08671 1.45321 8.08794 1.31601 8.1703C1.17873 8.25266 1.09265 8.40415 1.08904 8.56973C1.0884 8.59762 1.0884 8.62552 1.0884 8.65379C1.0884 10.9171 2.25193 12.9548 4.03083 14.0654C3.87801 14.0494 3.72527 14.0262 3.57353 13.9959C3.41709 13.9646 3.25623 14.022 3.15073 14.1469C3.04505 14.2717 3.0101 14.4457 3.05878 14.6046C3.71723 16.7568 5.41249 18.3398 7.4619 18.8224C5.76213 19.937 3.8183 20.5208 1.77774 20.5208C1.35196 20.5208 0.923745 20.4946 0.50465 20.4427C0.296457 20.4168 0.0973866 20.5455 0.0264836 20.7528C-0.0444194 20.9602 0.0307287 21.1914 0.207851 21.3102C2.82927 23.0699 5.8604 24 8.97336 24C15.0931 24 18.9214 20.9788 21.0552 18.4443C23.7161 15.284 25.2422 11.1009 25.2422 6.96778C25.2422 6.79511 25.2397 6.62074 25.2346 6.44694C26.2844 5.61887 27.1883 4.61672 27.9238 3.46488C28.0355 3.28995 28.0234 3.05875 27.894 2.89771C27.7647 2.73658 27.5493 2.68448 27.3654 2.76977Z'
											fill='#DADADA'
										/>
									</svg>
								</a>
							</li>
							<li>
								<a href='https://www.google.com/' target='_blank' rel='noopener noreferrer'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='35'
										height='22'
										viewBox='0 0 35 22'
										fill='none'
									>
										<path
											d='M33.5778 8.69389H31.4671V6.58308C31.4671 6.14123 31.1091 5.7832 30.6672 5.7832H27.8179C27.376 5.7832 27.018 6.14123 27.018 6.58308V8.69389H24.9073C24.4654 8.69389 24.1074 9.05191 24.1074 9.49376V12.3431C24.1074 12.785 24.4654 13.143 24.9073 13.143H27.018V15.2538C27.018 15.6955 27.376 16.0537 27.8179 16.0537H30.6672C31.1091 16.0537 31.4671 15.6955 31.4671 15.2538V13.143H33.5778C34.0197 13.143 34.3777 12.785 34.3777 12.3431V9.49376C34.3777 9.05191 34.0197 8.69389 33.5778 8.69389Z'
											fill='#DADADA'
										/>
										<path
											d='M11.6515 5.20589C12.6207 5.20589 13.5523 5.46718 14.347 5.96172L15.2549 6.58295C15.5713 6.79945 15.9969 6.76116 16.2695 6.49113L18.9174 3.86797C19.0869 3.7001 19.1725 3.46547 19.1511 3.22797C19.1298 2.99046 19.0035 2.77492 18.8068 2.64001L17.2679 1.5846C17.2365 1.56316 17.2036 1.54386 17.1695 1.52712L15.6787 0.791021C15.659 0.781209 15.6388 0.77225 15.6183 0.764145C14.3341 0.257132 12.981 0 11.5968 0C8.74316 0 6.05143 1.09529 4.01741 3.08378C1.95192 5.10308 0.803304 7.84899 0.783467 10.8158C0.763523 13.7832 1.87545 16.545 3.91417 18.5922C5.9371 20.6238 8.80715 21.7891 11.7885 21.7891C14.7383 21.7891 17.5058 20.6438 19.3815 18.6468C20.8672 17.0651 21.7789 14.9524 22.0186 12.5371C22.0208 12.5142 22.0222 12.4912 22.0224 12.4683L22.0502 10.2985C22.0508 10.2536 22.0476 10.2088 22.0406 10.1645L21.9243 9.42293C21.8634 9.03376 21.528 8.74687 21.1341 8.74687H11.8906C11.4488 8.74687 11.0907 9.105 11.0907 9.54675V12.7443C11.0907 13.1861 11.4488 13.5442 11.8906 13.5442H16.603C16.3871 14.0176 16.0467 14.5168 15.6164 14.982C14.6778 15.9971 13.278 16.5793 11.776 16.5793C10.242 16.5793 8.77302 15.9783 7.74566 14.9303C5.60712 12.7495 5.63165 8.94972 7.79782 6.79881C8.8176 5.7866 10.2223 5.20589 11.6515 5.20589Z'
											fill='#DADADA'
										/>
									</svg>
								</a>
							</li>
							<li>
								<a href='https://www.google.com/' target='_blank' rel='noopener noreferrer'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='22'
										height='27'
										viewBox='0 0 22 27'
										fill='none'
									>
										<path
											d='M19.1468 2.7668C17.2201 0.982645 14.5537 0 11.6388 0C7.18624 0 4.4477 1.76056 2.93444 3.2374C1.06945 5.05743 0 7.47408 0 9.86781C0 12.8733 1.30327 15.1801 3.48578 16.0383C3.6323 16.0963 3.77973 16.1254 3.92426 16.1254C4.38469 16.1254 4.74951 15.8348 4.8759 15.3687C4.94957 15.1012 5.12029 14.4415 5.1945 14.1551C5.35339 13.5895 5.22501 13.3174 4.87852 12.9236C4.24728 12.2031 3.95333 11.3512 3.95333 10.2424C3.95333 6.94914 6.4956 3.44901 11.2075 3.44901C14.9461 3.44901 17.2685 5.49868 17.2685 8.79809C17.2685 10.8802 16.8036 12.8083 15.9591 14.2277C15.3723 15.2139 14.3404 16.3895 12.7563 16.3895C12.0713 16.3895 11.4559 16.1181 11.0676 15.6449C10.7008 15.1976 10.5799 14.6197 10.7274 14.0174C10.8941 13.3369 11.1213 12.6271 11.3413 11.9409C11.7424 10.6876 12.1215 9.50389 12.1215 8.55948C12.1215 6.94409 11.092 5.85869 9.55986 5.85869C7.61272 5.85869 6.08727 7.76633 6.08727 10.2016C6.08727 11.3959 6.41634 12.2892 6.5653 12.6323C6.32001 13.6348 4.86218 19.5948 4.58565 20.7187C4.42577 21.3748 3.46258 26.5567 5.05682 26.9699C6.84805 27.4341 8.44916 22.3873 8.61211 21.817C8.74419 21.3532 9.20633 19.5994 9.48954 18.5213C10.3542 19.3248 11.7465 19.8679 13.1013 19.8679C15.6552 19.8679 17.952 18.7593 19.5686 16.7466C21.1365 14.7944 22 12.0734 22 9.0852C22 6.74911 20.96 4.44611 19.1468 2.7668Z'
											fill='#DADADA'
										/>
									</svg>
								</a>
							</li>
						</ul>
						<div>
							{/* run auathencation logic here */}
							{token && (
								<div className='footer-menu'>
									<div className='footer-menu--title'>Work With Us</div>
									<div className='footer-menu--submenu'>
										<MemberAuth />
										<MemberAuth />
									</div>
								</div>
							)}
						</div>
					</div>
					<div
						className='aside-menu--overlay aside-menu--close'
						onClick={hanldeClickOnAsideMenu}
						ref={asideMenuClose}
					/>
				</aside>
			</header>
		);
	} else {
		return (
			<header className='header'>
				<div className='age-check-logo' id='slides'>
					<img src={require('../../images/logo-anim/0_0001.png')} alt='' className='slide showing' />
					<img src={require('../../images/logo-anim/0_0002.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0003.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0004.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0005.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0006.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0007.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0008.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0009.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0010.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0011.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0012.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0013.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0014.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0015.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0016.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0017.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0018.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0019.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0020.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0021.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0022.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0023.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0024.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0025.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0026.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0027.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0028.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0029.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0030.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0031.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0032.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0033.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0034.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0035.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0036.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0037.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0038.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0039.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0040.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0041.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0042.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0043.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0044.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0045.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0046.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0047.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0048.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0049.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0050.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0051.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0052.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0053.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0054.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0055.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0056.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0057.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0058.png')} alt='' className='slide' />
					<img src={require('../../images/logo-anim/0_0060.png')} alt='' className='slide' />
				</div>

				<div className='container'>
					<div className='header--logo'>
						<Link to='/'>
							<img src={logo} alt='' />
						</Link>
					</div>
					<ul className='header-menu'>
						<li className='header-menu-item'>
							<NavLink
								className='header-menu--link'
								activeClassName='header-menu--link__active'
								to='/new-models'
							>
								New models
							</NavLink>
						</li>
						<li className='header-menu-item'>
							<NavLink
								className='header-menu--link'
								activeClassName='header-menu--link__active'
								to='/top-models'
							>
								TOP Models
							</NavLink>
						</li>
						<li className='header-menu-item'>
							<NavLink className='header-menu--link' activeClassName='header-menu--link__active' to='/clubs'>
								Clubs
							</NavLink>
						</li>
						<Category />
					</ul>

					{!token ? (
						<MemberAuth />
					) : (
						<div className='header-profile'>
							{/* <div className='header-profile--cash'>
                        <svg
                           xmlns='http://www.w3.org/2000/svg'
                           width='19'
                           height='19'
                           viewBox='0 0 19 19'
                           fill='none'
                        >
                           <path
                              d='M10.133 9.06546H8.87063C8.34616 9.06546 7.91935 8.63865 7.91935 8.11418C7.91935 7.58972 8.34616 7.16291 8.87063 7.16291H11.0987C11.3374 7.16291 11.5328 6.96759 11.5328 6.72886C11.5328 6.49014 11.3374 6.29482 11.0987 6.29482H9.93765V5.40865C9.93765 5.16993 9.74233 4.97461 9.50361 4.97461C9.26489 4.97461 9.06957 5.16993 9.06957 5.40865V6.29482H8.87063C7.86872 6.29482 7.05127 7.11227 7.05127 8.11418C7.05127 9.1161 7.86872 9.93355 8.87063 9.93355H10.133C10.6574 9.93355 11.0842 10.3604 11.0842 10.8848C11.0842 11.4093 10.6574 11.8361 10.133 11.8361H7.8651C7.62638 11.8361 7.43106 12.0314 7.43106 12.2701C7.43106 12.5089 7.62638 12.7042 7.8651 12.7042H9.06595V13.6084C9.06595 13.8472 9.26127 14.0425 9.49999 14.0425C9.73872 14.0425 9.93404 13.8472 9.93404 13.6084V12.7042H10.1438C10.1474 12.7042 10.1511 12.7042 10.1547 12.7042C11.1457 12.6897 11.9487 11.8795 11.9487 10.8848C11.9487 9.87929 11.1349 9.06546 10.133 9.06546Z'
                              fill='#CCCCCC'
                              stroke='#CCCCCC'
                              strokeWidth='0.3'
                           />
                           <path
                              d='M15.5115 3.48851C13.9055 1.88255 11.7715 1 9.5 1C7.22851 1 5.09447 1.88255 3.48851 3.48851C1.88255 5.09447 1 7.22851 1 9.5C1 11.7715 1.88255 13.9055 3.48851 15.5115C5.09447 17.1174 7.22851 18 9.5 18C11.7715 18 13.9055 17.1174 15.5115 15.5115C17.1174 13.9055 18 11.7715 18 9.5C18 7.22851 17.1174 5.09447 15.5115 3.48851ZM9.5 17.1319C5.2934 17.1319 1.86809 13.7066 1.86809 9.5C1.86809 5.2934 5.2934 1.86809 9.5 1.86809C13.7066 1.86809 17.1319 5.2934 17.1319 9.5C17.1319 13.7066 13.7066 17.1319 9.5 17.1319Z'
                              fill='#CCCCCC'
                              stroke='#CCCCCC'
                              strokeWidth='0.3'
                           />
                        </svg>
                        {decodedToken?.balance}
                        <span className='header-profile--cash-buy'>credits</span>
                     </div> */}

							{/* <div className='header-profile--message header-profile--message__new'>
								<div className='header-profile--message-icon'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='22'
										height='16'
										viewBox='0 0 22 16'
										fill='none'
									>
										<path
											d='M18.4579 0H2.57096C1.1528 0 0 1.1528 0 2.57096V13.429C0 14.8472 1.1528 16 2.57096 16H18.4535C19.8717 16 21.0245 14.8472 21.0245 13.429V2.57531C21.0288 1.15715 19.876 0 18.4579 0ZM19.8543 13.429C19.8543 14.199 19.2278 14.8254 18.4579 14.8254H2.57096C1.80098 14.8254 1.17455 14.199 1.17455 13.429V2.57531C1.17455 1.80533 1.80098 1.1789 2.57096 1.1789H18.4535C19.2235 1.1789 19.8499 1.80533 19.8499 2.57531V13.429H19.8543Z'
											fill='#CCCCCC'
										/>
										<path
											d='M13.2593 7.8655L18.4013 3.2543C18.6405 3.03679 18.6623 2.66703 18.4448 2.42342C18.2273 2.18416 17.8575 2.1624 17.6139 2.37991L10.5231 8.74424L9.13971 7.50879C9.13536 7.50444 9.13101 7.50009 9.13101 7.49574C9.10056 7.46529 9.07011 7.43919 9.0353 7.41308L3.40616 2.37556C3.16255 2.15805 2.79278 2.17981 2.57527 2.42342C2.35776 2.66703 2.37951 3.03679 2.62312 3.2543L7.82595 7.90466L2.64487 12.7551C2.40996 12.977 2.39691 13.3467 2.61877 13.586C2.73623 13.7078 2.89283 13.7731 3.04944 13.7731C3.193 13.7731 3.33655 13.7209 3.44966 13.6165L8.70904 8.69639L10.1359 9.971C10.249 10.0711 10.3882 10.1189 10.5274 10.1189C10.6666 10.1189 10.8102 10.0667 10.9189 9.96665L12.385 8.65289L17.6139 13.6208C17.727 13.7296 17.8749 13.7818 18.0184 13.7818C18.1751 13.7818 18.3273 13.7209 18.4448 13.5991C18.6666 13.3641 18.6579 12.99 18.423 12.7682L13.2593 7.8655Z'
											fill='#CCCCCC'
										/>
									</svg>
									</div>
									
								<div className='header-profile--message-menu'>
									<div className='header-profile--message-menu-top'>
										<div className='header-profile--message-menu__all'>Messages</div>
										<div className='header-profile--message-menu__new'>New Messages</div>
									</div>
									<div className='header-profile--message-menu-message-content header-profile--message-menu-message-content__null'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='82'
											height='57'
											viewBox='0 0 82 57'
											fill='none'
										>
											<g opacity='0.7'>
												<path
													d='M42.6856 37.3786C42.2072 37.8114 41.5922 38.0164 41 38.0164C40.4078 38.0164 39.7928 37.8114 39.3145 37.3786L22.8233 22.9375L2.16382 56.4437C2.30049 56.4665 2.41437 56.4892 2.55104 56.4892H79.4262C79.6084 56.4892 79.8134 56.4437 79.9957 56.3981L59.0856 23.0514L42.6856 37.3786Z'
													fill='#B87693'
												/>
												<path d='M77.8318 0H4.30493L41 32.1395L77.8318 0Z' fill='#B87693' />
												<path d='M0 2.93945V50.1807L18.9056 19.4989L0 2.93945Z' fill='#B87693' />
												<path d='M63.0032 19.635L81.9999 49.9295V3.05273L63.0032 19.635Z' fill='#B87693' />
											</g>
										</svg>
									</div>
									<div className='header-profile--message-menu-bottom'>
										<div className='header-profile--message-menu__view'>View All</div>
									</div>
								</div>
							</div> */}

							<div className='header-profile--user'>
								<div className='header-profile--user-icon'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='18'
										height='21'
										viewBox='0 0 18 21'
										fill='none'
									>
										<path
											d='M8.65008 11.0529C8.67132 11.0529 8.69256 11.0529 8.71805 11.0529C8.72655 11.0529 8.73504 11.0529 8.74354 11.0529C8.75628 11.0529 8.77327 11.0529 8.78602 11.0529C10.0306 11.0317 11.0374 10.5941 11.7807 9.7573C13.4162 7.91373 13.1443 4.75334 13.1146 4.45174C13.0084 2.18764 11.9379 1.10444 11.0544 0.598946C10.3959 0.220888 9.62709 0.0169914 8.76902 0H8.73929C8.73504 0 8.72655 0 8.7223 0H8.69681C8.2253 0 7.29927 0.0764612 6.41147 0.581955C5.51942 1.08745 4.43197 2.17065 4.32578 4.45174C4.29604 4.75334 4.02418 7.91373 5.6596 9.7573C6.39873 10.5941 7.40547 11.0317 8.65008 11.0529ZM5.45995 4.55794C5.45995 4.54519 5.4642 4.53245 5.4642 4.52395C5.60438 1.47825 7.76653 1.15117 8.69256 1.15117H8.70955C8.71805 1.15117 8.73079 1.15117 8.74354 1.15117C9.89045 1.17665 11.8402 1.64392 11.9719 4.52395C11.9719 4.5367 11.9719 4.54944 11.9761 4.55794C11.9804 4.58767 12.2777 7.47621 10.9269 8.99694C10.3917 9.60013 9.67806 9.89748 8.73929 9.90597C8.73079 9.90597 8.72655 9.90597 8.71805 9.90597C8.70955 9.90597 8.70531 9.90597 8.69681 9.90597C7.76229 9.89748 7.0444 9.60013 6.51342 8.99694C5.16685 7.4847 5.45571 4.58343 5.45995 4.55794Z'
											fill='#613E4D'
										/>
										<path
											d='M17.4471 16.2951C17.4471 16.2909 17.4471 16.2866 17.4471 16.2824C17.4471 16.2484 17.4428 16.2144 17.4428 16.1762C17.4173 15.3351 17.3621 13.3684 15.5185 12.7397C15.5058 12.7354 15.4888 12.7312 15.4761 12.727C13.5603 12.2384 11.9673 11.134 11.9503 11.1213C11.6912 10.9386 11.3344 11.0023 11.1517 11.2614C10.9691 11.5206 11.0328 11.8774 11.2919 12.06C11.3641 12.111 13.0548 13.2877 15.1702 13.8314C16.16 14.184 16.2704 15.2417 16.3001 16.2102C16.3001 16.2484 16.3001 16.2824 16.3044 16.3164C16.3086 16.6987 16.2831 17.2891 16.2152 17.629C15.527 18.0198 12.8296 19.3706 8.72623 19.3706C4.6398 19.3706 1.92543 18.0155 1.23303 17.6247C1.16507 17.2849 1.13533 16.6944 1.14383 16.3121C1.14383 16.2782 1.14807 16.2442 1.14807 16.2059C1.17781 15.2374 1.28825 14.1797 2.278 13.8271C4.39343 13.2834 6.08407 12.1025 6.15628 12.0558C6.4154 11.8731 6.47912 11.5163 6.29646 11.2572C6.1138 10.9981 5.75699 10.9344 5.49787 11.117C5.48088 11.1298 3.89643 12.2342 1.97216 12.7227C1.95516 12.727 1.94242 12.7312 1.92968 12.7354C0.0861131 13.3684 0.0308911 15.3351 0.00540405 16.172C0.00540405 16.2102 0.00540396 16.2442 0.00115611 16.2782C0.00115611 16.2824 0.00115611 16.2866 0.00115611 16.2909C-0.00309173 16.5118 -0.00733949 17.646 0.217796 18.2152C0.260275 18.3256 0.336736 18.4191 0.438684 18.4828C0.56612 18.5677 3.62032 20.5133 8.73048 20.5133C13.8406 20.5133 16.8948 18.5635 17.0223 18.4828C17.12 18.4191 17.2007 18.3256 17.2432 18.2152C17.4555 17.6502 17.4513 16.516 17.4471 16.2951Z'
											fill='#613E4D'
										/>
									</svg>
								</div>
								<ul className='header-profile--user-menu'>
									<li>
										<Link to={profileLink}>My profile</Link>
									</li>
									<li>
										<Link to='/lk-favourite'>My Favorites</Link>
									</li>

									<li>
										<Link to='/' onClick={handleLogout}>
											Log out
										</Link>
									</li>
								</ul>
							</div>
						</div>
					)}
				</div>
			</header>
		);
	}
};

export default Header;
