import styles from './memberViewLiveStreaming.module.scss';
import notification from '../../../utils/notification';
import errorFormmatter from '../../../utils/errorFormatter';
import Spinner from '../../../custom/spinner/Spinner';
import { RiFullscreenFill } from 'react-icons/ri';
import { CiVolumeHigh } from 'react-icons/ci';
import Tab from '../../../custom/Tabs/Tabs';
import { getModelDetails } from '../../../request/model';
import { useSelector } from 'react-redux';
import { getMemberProfile } from '../../../request/member';
import { useState, useEffect, useRef } from 'react';
import { ReactComponent as VibratorIcon } from '../../../svgs/vibrator.svg';
import { ReactComponent as CamIcon } from '../../../svgs/cam.svg';
import { ReactComponent as SendIcon } from '../../../svgs/send-icon.svg';
import UnavailableModal from './unavailableModal/UnavailableModal';
import { TfiMenuAlt } from 'react-icons/tfi';
import classNames from 'classnames';
import { EventTypes } from './constant';
import React from 'react';
import PrivateRequestModal from './PrivateRequestModal/PrivateRequestModal';
import SendTipModal from './sendTipModal/SendTipModal';
import Button from '../../../custom/btn/Btn';
import { useHistory } from 'react-router-dom';
import { updateIsOnPrivateCall } from '../../../store/slices/broadcast';
import { useDispatch } from 'react-redux';
import TipMenu from './tipMenu/TipMenu';
import { getStreamingConfig } from '../../../request/model';
import { useLocation } from 'react-router-dom';
import { updateStreamingMsgs, resetStreamingMsgs } from '../../../store/slices/broadcast';
import { useAgoraStreaming } from '../../../hooks/useAgora';
import {
	getStreamingTipFromMembers,
	sendTipFromMember,
	getStreamingGoals,
	getStreamMemberDisabled,
} from '../../../request/model';
import {
   createPrivatChat,
   makeInitialBroadcastPayment,
   getModelOnBroadcast,
   updateModelBroadcastStatus,
} from '../../../request/member';
import { useMutation } from 'react-query';
import { getMemberMonthlySub } from '../../../request/member';
import { v4 as uuidv4 } from 'uuid';

const MemberViewLiveStreaming = () => {
   const history = useHistory();
   const params = useLocation();

   const generalSettings = useRef({});

   const [currentChannelRoomNameAndTopic, setCurrentChannelRoomNameAndTopic] = useState({
      channelName: '###',
      topic: '###',
   });
   const liveMessages = useSelector((state) => state.liveBroadcast.streamingMsgs);
   const liveBroadcast = useSelector((state) => state.liveBroadcast);
   const [memberAllowedToJoin, setMemberAllowedToJoin] = useState({
      kickOut: false,
      disableChat: false,
   });

   const memberAllowedToJoinRef = useRef(memberAllowedToJoin);
   const [currentTab, setCurrentTab] = useState(!liveBroadcast?.isOnPrivateCall ? 0 : 1);
   const [memberProfile, setMemberProfile] = useState({});
   const fullScreenRef = useRef(null);
   const dispatch = useDispatch();
   const [openSendTipModal, setOpenSendTipModal] = useState(false);
   const [isFullScreen, setIsFullScreen] = useState(false);
   const broadcasterRef = useRef(null);
   const messageContentRef = useRef(null);
   const [isJoin, setIsJoin] = useState(false);
   const [localTracks, setLocalTracks] = useState([]);
   const [isVideoMuted, setIsVideoMuted] = useState(false);
   const [isAudioMuted, setIsAudioMuted] = useState(false);
   const [remoteUsers, setRemoteUsers] = useState({});
   const [hostAllowedVideo, setHostAllowedVideo] = useState(true);
   const [hostAllowedAudio, setHostAllowedAudio] = useState(true);
   const user = useSelector((state) => state?.user);
   const [isSending, setIsSending] = useState(false);
   const [message, setMessage] = useState('');
   const [isModelOnline, setIsModelOnline] = useState(false);
   const [openUnAvailable, setOpenUnAvailable] = useState(false);
   const [broadcastModelDetail, setBroadcastModelDetail] = useState(null);
   const [memberTips, setMemberTips] = useState([]);
   const [highestTip, setHighestTip] = useState(null);
   const [modelGoal, setModelGoals] = useState([]);
   const [privateRequestTimeOutId, setPrivateRequestTimeOutId] = useState('');
   const [currentGoalDetails, setCurrentGoalDetails] = useState({
      totalCredit: 0,
      totalEarned: 0,
      percentageOfTotalGoalEarned: 0,
      leftCreditToEarn: 0,
   });
   const [openPrivateRequestModal, setOpenPrivateRequestModal] = useState(false);
   const [privateRequestUser, setPrivateRequestUser] = useState('');
   const [openPrivateRequestConfirmation, setOpenPrivateRequestConfirmation] = useState(false);

   const isModelOnlineRef = useRef(isModelOnline);
   const isJoinRef = useRef(isJoin);

   const isVideoMutedRef = useRef(isVideoMuted);
   const isAudioMutedRef = useRef(isAudioMuted);

   const {
      getChannelConfigs,
      updateRtcMemberDetails,
      registerRtmMessagingEvent,
      registerRtcEvent,
      createLocalRtcTrack,
      initStreaming,
      micChangeDevice,
      camChangeDevice,
      getRemoteUsers,
      preSubScribeUser,
      publishTrack,
      subScribeUserTrack,
      sendMessage,
      handleBroadcastingChannelMessage,
      leaveStreamingChannel,
      handleBroadcastingLocalChannelMessage,
      unsubScribeUserTrack,
      broadcastMessage,
      joinChatChannel,
      getToken,
   } = useAgoraStreaming();

   const sendPrivateChatMutation = useMutation({
      mutationKey: ['send-private-message'],
      mutationFn: (chat) => createPrivatChat(chat),
   });

   const urlSearchParams = new URLSearchParams(params.search);
   const memberId = user?.clientId;
   const modelFirstName = urlSearchParams.get('firstName');
   const modelLastName = urlSearchParams.get('lastName');
   const modelId = urlSearchParams.get('model');
   const memberFullName = user?.fullName;
   const modelFullName = `${modelFirstName} ${modelLastName}`;

   let link = `broadcast-${modelId ?? ''}`;
   let privateLink = `private-channel-${user.clientId}`;
   const linkRef = useRef(liveBroadcast?.isOnPrivateCall ? privateLink : link);

   const resetModelRemoteView = (node) => {
      try {
         if (node) {
            var innerDiv = node.querySelectorAll('div') ?? [];

            innerDiv.forEach(function(inner) {
               if (inner.id.includes('agora-video-player')) {
                  inner?.remove();
               }
            });
         }
      } catch (error) {}
   };

   const persistMessage = async (message) => {
      try {
         dispatch(
            updateStreamingMsgs({
               message: { ...message },
               type: liveBroadcast?.isOnPrivateCall ? 'private_msg' : 'general_msg',
               size: 20,
            })
         );
      } catch (error) {}
   };

   const handleLiveMessages = async () => {
      try {
         if (currentTab === 0) {
                                  if (messageContentRef.current) {
                                     messageContentRef.current.innerHTML = '';
                                  }
                                  //handleBroadcastingLocalChannelMessage(liveMessages?.general ?? [], messageContentRef);
                               }

         if (currentTab === 1) {
                                  if (messageContentRef.current) {
                                     messageContentRef.current.innerHTML = '';
                                  }
                                  //handleBroadcastingLocalChannelMessage(liveMessages?.private ?? [], messageContentRef);
                               }
      } catch (error) {}
   };

   const handleMemberJoinedMessagingEvent = async (event) => {
      const { eventType, publisher } = event;

      if (eventType === EventTypes.REMOTE_JOIN) {
         const config = await getChannelConfigs(
            liveBroadcast?.isOnPrivateCall ? privateLink : link
         );
         const { metadata } = config;

         const isHost = publisher?.split('-')[0];

         if (isHost === 'host' && metadata) {
            await preCheckHostOnline(parseInt(metadata.hostId.value));
         }
      } else if (eventType === EventTypes.REMOTE_LEAVE) {
         const isHost = publisher?.split('-')[0];
         if (isHost === 'host') {
            const hostPreview = document.querySelector('#liveBroadCast');
            if (hostPreview !== null) {
               resetModelRemoteView(hostPreview);

               setIsModelOnline(false);
               isModelOnlineRef.current = false;
               setOpenUnAvailable(true);
            }
         }
      }
   };

   const handleChannelMessage = async (event) => {
      try {
         const { message } = event;

         const parsedMessage = JSON.parse(message);
         if (parsedMessage?.type === 'tip') {
            await calculateHighestTip(memberTips);
         }

         if (parsedMessage?.type === 'privateRequestEnded') {
            await endBroadcast();
            linkRef.current = link;
            dispatch(
               updateIsOnPrivateCall({
                  isOnPrivateCall: false,
               })
            );
            history.push('/');
         }

         if (parsedMessage?.type === 'privateRequestDeclined') {
            setPrivateRequestUser(parsedMessage?.text?.user);
            setOpenPrivateRequestModal(true);
            setOpenUnAvailable(false);
         }

         if (parsedMessage?.type === 'privateRequestAccepted') {
            setOpenPrivateRequestModal(true);
            setPrivateRequestUser(parsedMessage?.text?.user);
            setOpenUnAvailable(false);
            setOpenPrivateRequestConfirmation(true);
         }

         if (parsedMessage?.type === 'bot' || parsedMessage?.type === 'chat') {
                                                                                 //await persistMessage(parsedMessage);
                                                                              }

         await handleBroadcastingChannelMessage(message, messageContentRef);
      } catch (error) {}
   };

   const handleUserLeft = async (user) => {
      try {
         const config = await getChannelConfigs(
            liveBroadcast?.isOnPrivateCall ? privateLink : link
         );
         const { metadata } = config;
         let userType = null;
         let hostId = null;
         const uid = user.uid.toString();

         if (metadata && metadata?.hostId.value === uid) {
            hostId = metadata?.hostId?.value ?? '';
            userType = 'host';
         } else {
            userType = 'audience';
            hostId = null;
         }

         if (userType === 'host') {
            delete remoteUsers['host'];
            setIsModelOnline(false);
            isModelOnlineRef.current = false;
            setOpenUnAvailable(true);
            setRemoteUsers(() => {
               return { ...remoteUsers };
            });
            const hostPreview = document.querySelector('#liveBroadCast');
            if (hostPreview !== null) {
               resetModelRemoteView(hostPreview);
            }
         } else {
            delete remoteUsers[uid];
            setRemoteUsers(() => {
               return { ...remoteUsers };
            });
         }
      } catch (error) {}
   };

   const handleUserPublish = async (user, mediaType) => {
      try {
         await subScribeUserTrack(user, mediaType);
         const config = await getChannelConfigs(
            liveBroadcast?.isOnPrivateCall ? privateLink : link
         );
         const { metadata } = config;
         let userType = null;
         let hostId = null;
         const uid = user.uid.toString();

         if (metadata && metadata?.hostId.value === uid) {
            hostId = metadata?.hostId?.value ?? '';
            userType = 'host';
         } else {
            userType = 'audience';
            hostId = null;
         }

         if (userType === 'host') {
            setIsModelOnline(true);
            isModelOnlineRef.current = true;
            setOpenUnAvailable(false);
            setRemoteUsers((prev) => {
               return {
                  ...prev,
                  ['host']: user,
               };
            });
         } else {
            setRemoteUsers((prev) => {
               return {
                  ...prev,
                  [uid]: user,
               };
            });
         }

         if (mediaType === 'video' && userType === 'host') {
            const hostPreview = document.querySelector('#liveBroadCast');
            if (hostPreview !== null) {
               resetModelRemoteView(hostPreview);

               user?.videoTrack?.play('liveBroadCast');
            }
         }

         if (mediaType === 'audio') {
            user?.audioTrack?.play();
         }
      } catch (error) {}
   };

   const handleUserUnPublish = async (user, mediaType) => {
      try {
         await unsubScribeUserTrack(user, mediaType);
         const config = await getChannelConfigs(
            liveBroadcast?.isOnPrivateCall ? privateLink : link
         );
         const { metadata } = config;
         let userType = null;
         let hostId = null;
         const uid = user.uid.toString();

         if (metadata && metadata?.hostId.value === uid) {
            hostId = metadata?.hostId?.value ?? '';
            userType = 'host';
         } else {
            userType = 'audience';
            hostId = null;
         }

         if (userType === 'host') {
            delete remoteUsers['host'];
         } else {
            delete remoteUsers[uid];
         }

         if (mediaType === 'video' && userType === 'host') {
            const hostPreview = document.querySelector('#liveBroadCast');
            if (hostPreview !== null) {
               resetModelRemoteView(hostPreview);
               user?.videoTrack?.stop('liveBroadCast');
            }
         }

         if (mediaType === 'audio') {
            user?.audioTrack?.stop();
         }
      } catch (error) {}
   };

   const getAllStreamingTipFromMember = async () => {
      try {
         const response = await getStreamingTipFromMembers(modelId);

         setMemberTips(response);
         await calculateHighestTip(response);
      } catch (error) {
         setMemberTips([]);
      }
   };

   const getAllStreamingGoals = async () => {
      try {
         const response = await getStreamingGoals(modelId);
         setModelGoals(response);

         await calculateModelGoalProgress(response);
      } catch (error) {
         setModelGoals([]);
      }
   };

   const calculateModelGoalProgress = async (goals) => {
      try {
         const initialValue = 0;
         let totalCredit = 0;
         let totalEarned = 0;
         const completedGoals = goals.filter((goal) => {
            return goal.setGoal === 'completed';
         });

         totalCredit = goals.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount,
            initialValue
         );

         totalEarned = completedGoals.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount,
            initialValue
         );

         const leftCreditToEarn = totalCredit - totalEarned;
         const percentageOfTotalGoalEarned = parseInt((totalEarned / totalCredit) * 100).toFixed(1);

         setCurrentGoalDetails((prev) => {
            return {
               ...prev,
               totalCredit,
               totalEarned,
               leftCreditToEarn,
               percentageOfTotalGoalEarned,
            };
         });
      } catch (error) {}
   };

   const calculateHighestTip = async (tips) => {
      let sortedTip = tips.sort((a, b) => {
         return a?.credit <= b?.credit;
      });

      setHighestTip([...sortedTip?.slice(0, 2)] ?? null);
   };

   const joinPrivateCall = async () => {
      const id = setTimeout(async () => {
         try {
            resetModelRemoteView();
            await joinShow(privateLink);
            setIsJoin(true);
            isJoinRef.current = true;
            setOpenPrivateRequestConfirmation(false);
            setCurrentTab(1);
            if (privateRequestTimeOutId) {
               clearTimeout(privateRequestTimeOutId);
            }
         } catch (error) {
            setIsJoin(false);
            isJoinRef.current = false;
            linkRef.current = link;
            dispatch(
               updateIsOnPrivateCall({
                  isOnPrivateCall: false,
               })
            );
         }
      }, 10000);
      setPrivateRequestTimeOutId(id);
   };

   const startPrivateCall = async () => {
      try {
         await endBroadcast();
         setIsJoin(false);
         isJoinRef.current = false;
         linkRef.current = privateLink;
         dispatch(
            updateIsOnPrivateCall({
               isOnPrivateCall: true,
            })
         );
      } catch (error) {
         //   notification({
         //     title: 'Private show',
         //     type: 'warning',
         //     message: 'Error occurred while joining the broadcast',
         //   });
      }
   };

   const sendOfflineMessage = () => {
      sendPrivateChatMutation.mutate(
         {
            message: `Hi ${modelFullName}`,
            senderName: memberFullName,
            recieverName: modelFullName,
            senderId: memberId,
            receiverId: modelId,
         },
         {
            onError: (error) => {
               notification({
                  title: 'Private show',
                  type: 'danger',
                  message: error?.response?.data?.message ?? error?.message,
               });
            },
            onSuccess: (response) => {
               notification({
                  title: 'Private show',
                  type: 'success',
                  message: response?.data?.message,
               });
            },
         }
      );
   };

   const handleBookPrivateRequest = async () => {
      if (generalSettings.current?.allowPrivateChat === false) {
         notification({
            title: 'Broadcasting',
            message: 'Private show are not allowed',
            type: 'warning',
         });
         return;
      }

      if (memberAllowedToJoinRef.current?.kickOut === true) {
         notification({
            title: 'Broadcasting',
            message: 'You have been kick out from the streaming',
            type: 'warning',
         });
         return;
      }

      if (liveBroadcast?.isOnPrivateCall) {
         const msgPayload = {
            type: 'privateRequestEnded',
            text: {
               message: `${user?.fullName} has ended the private broadcast`,
               link: '',
               user: user?.fullName,
            },
            author: `${user?.fullName}`,
            messageId: `message-${uuidv4()}`,
         };
         await sendMessage(liveBroadcast?.isOnPrivateCall ? privateLink : link, msgPayload, null);
         endBroadcast().then(() => {
            dispatch(
               updateIsOnPrivateCall({
                  isOnPrivateCall: false,
               })
            );
            history.push('/');
         });
         return;
      }

      const config = await getChannelConfigs(liveBroadcast?.isOnPrivateCall ? privateLink : link);

      const allowedPrivateRequest = config?.metadata?.allowPrivateChat?.value ?? '';

      if (allowedPrivateRequest) {
         const msgPayload = {
            type: 'privateRequest',
            text: {
               message: `${user?.fullName} has requested for a private broadcast`,
               link: privateLink,
               user: user?.fullName,
            },
            author: `${user?.fullName}`,
            messageId: `message-${uuidv4()}`,
         };
         await sendMessage(liveBroadcast?.isOnPrivateCall ? privateLink : link, msgPayload, null);
         notification({
            title: 'Private show',
            type: 'success',
            message: 'Successfully sent a private request',
         });
      } else {
         // handle private message
         notification({
            title: 'Broadcasting',
            message: 'Private show are not allowed',
            type: 'warning',
         });
      }
   };

   const preCheckHostOnline = async (remoteUserId) => {
      try {
         if (memberAllowedToJoinRef.current?.kickOut === true) {
            notification({
               title: 'Broadcasting',
               message: 'You have been kick out from the streaming',
               type: 'warning',
            });

            if (isJoin) {
               await endBroadcast();
               setIsModelOnline(false);
               setOpenUnAvailable(true);
               setIsJoin(false);
               dispatch(
                  updateIsOnPrivateCall({
                     isOnPrivateCall: false,
                  })
               );
            }

            history.push('/');
         }

         const config = await getChannelConfigs(
            liveBroadcast?.isOnPrivateCall ? privateLink : link
         );

         const { metadata } = config;

         if (metadata) {
            const allowCamOn = metadata.allowCamOn.value;
            const allowMicOff = metadata.allowMicOff.value;
            const videoTrack = await preSubScribeUser(remoteUserId, 'video');
            const audioTrack = await preSubScribeUser(remoteUserId, 'audio');

            const hostPreview = document.querySelector('#liveBroadCast');

            if (allowCamOn === 'true') {
               if (hostPreview !== null) {
                  if (isModelOnlineRef.current && isJoinRef.current) {
                     if (isVideoMutedRef.current) {
                        videoTrack?.stop('liveBroadCast');
                        resetModelRemoteView(hostPreview);
                     } else {
                        resetModelRemoteView(hostPreview);
                        videoTrack?.play('liveBroadCast');
                     }
                  } else {
                     resetModelRemoteView(hostPreview);
                     videoTrack?.play('liveBroadCast');
                  }
               }
            }

            if (allowMicOff === 'true') {
               if (isModelOnlineRef.current && isJoinRef.current) {
                  if (isAudioMutedRef.current) {
                     audioTrack.stop();
                  } else {
                     audioTrack.play();
                  }
               } else {
                  audioTrack.play();
               }
            }
         }
      } catch (error) {}
   };

   const checkHostModelOnline = async (channel = '') => {
      try {
         const remoteUsers = await getRemoteUsers();

         const config = await getChannelConfigs(linkRef.current);

         const { metadata } = config ?? {};

         if (remoteUsers?.length > 0 && metadata) {
            setCurrentChannelRoomNameAndTopic((prev) => {
               return {
                  ...prev,
                  channelName: metadata?.channelName?.value,
                  topic: metadata?.topic?.value,
               };
            });

            const hostUser = remoteUsers?.find((user) => {
               return user.uid === parseInt(metadata.hostId.value);
            });
            if (hostUser) {
               await preCheckHostOnline(parseInt(hostUser?.uid));
               setIsModelOnline(true);
               isModelOnlineRef.current = true;
               setOpenUnAvailable(false);
               await handleMemberInitialPayment();
               await updateMemberBroadcastStatus();
               return true;
            } else {
               setIsModelOnline(false);
               isModelOnlineRef.current = false;
               //   setOpenUnAvailable(true);
               return false;
            }
         } else {
            setIsModelOnline(false);
            isModelOnlineRef.current = false;
            // setOpenUnAvailable(true);

            return false;
         }
      } catch (error) {}
   };

   const muteVideo = async () => {
      try {
         if (isVideoMuted === false) {
            if (localTracks[1]) {
               localTracks[1].setEnabled(false);
               const hostPreview = document.querySelector('#liveBroadCast');
               if (hostPreview !== null) {
                  resetModelRemoteView(hostPreview);
               }
               // remoteUsers?.host?.videoTrack?.stop();
               setIsVideoMuted(true);
               isVideoMutedRef.current = true;
            }
         } else {
            if (localTracks[1]) {
               const hostPreview = document.querySelector('#liveBroadCast');
               if (hostPreview !== null) {
                  localTracks[1].setEnabled(true);
                  const config = await getChannelConfigs(
                     liveBroadcast?.isOnPrivateCall ? privateLink : link
                  );
                  const { metadata } = config;
                  if (metadata && isVideoMuted) {
                     await preCheckHostOnline(parseInt(metadata.hostId.value));
                  }
                  // remoteUsers?.host?.videoTrack?.play(`liveBroadCast`);
                  setIsVideoMuted(false);
                  isVideoMutedRef.current = false;
               }
            }
         }
      } catch (error) {
         notification({
            title: 'Broadcasting',
            type: 'warning',
            message: 'Error occurred taking the action',
         });
      }
   };

   const muteAudio = () => {
      try {
         if (isAudioMuted === false) {
            if (localTracks[0]) {
               localTracks[0].setEnabled(false);
               // remoteUsers?.host?.audioTrack?.stop();
               // [...document.querySelectorAll('audio, video')].forEach(
               //   (el) => (el.muted = false)
               // );
               setIsAudioMuted(true);
               isAudioMutedRef.current = true;
            }
         } else {
            if (localTracks[0]) {
               // remoteUsers?.host?.audioTrack?.play();
               localTracks[0].setEnabled(true);
               setIsAudioMuted(false);
               isAudioMutedRef.current = false;
               // [...document.querySelectorAll('audio, video')].forEach(
               //   (el) => (el.muted = true)
               // );
            }
         }
      } catch (error) {
         notification({
            title: 'Broadcasting',
            type: 'warning',
            message: 'Error occurred taking the action',
         });
      }
   };

   const addLocalUserToView = async (localTrack) => {
      try {
         await publishTrack(localTrack);
      } catch (error) {}
   };

   const sendChat = async (event) => {
      event.preventDefault();

      if (
         generalSettings.current?.allowGeneralChat === false ||
         generalSettings.current?.allowMessage === false
      ) {
         notification({
            title: 'Broadcasting',
            message: 'Messages are not allowed',
            type: 'warning',
         });
         return;
      }

      if (
         memberAllowedToJoinRef.current?.kickOut === true ||
         memberAllowedToJoinRef.current?.disableChat === true
      ) {
         notification({
            title: 'Broadcasting',
            message: 'You have been disabled for chat by the model',
            type: 'warning',
         });
         return;
      }

      if (isModelOnline) {
         if (message && message !== '') {
            setIsSending(true);
            const payload = {
               type: 'chat',
               author: `${user?.fullName}`,
               text: message,
               messageId: `message-${uuidv4()}`,
            };
            setIsSending(false);
            // await broadcastMessage(liveBroadcast?.isOnPrivateCall ? privateLink : link, payload);
            await sendMessage(
               liveBroadcast?.isOnPrivateCall ? privateLink : link,
               payload,
               messageContentRef,
               null
            );
            //await persistMessage(payload);
         }
      } else {
      }
      setMessage('');
   };

   const onMessageChange = (event) => {
      const message = event.target.value;
      setMessage(message);
   };

   const updateMemberBroadcastStatus = async () => {
      try {
         const response = await getModelOnBroadcast(modelId);

         if (isModelOnlineRef.current && response?.onlineForBrocast === true) {
            const payload = new FormData();

            payload.append('ModelId', modelId);
            payload.append('BroadCastId', liveBroadcast?.isOnPrivateCall ? privateLink : link);
            payload.append(
               'IsBroadCastEnded',
               response?.onlineForBrocast ?? isModelOnlineRef.current
            );

            await updateModelBroadcastStatus({
               payload: payload,
            });
         }
      } catch (error) {}
   };

   const handleMemberInitialPayment = async () => {
      try {
         const response = await makeInitialBroadcastPayment({
            modelId,
            memberId: user?.clientId,
            broadCastId: liveBroadcast?.isOnPrivateCall ? privateLink : link,
         });

         if (response && response?.data?.isSuccessful === false) {
            notification({
               message: response?.message ?? '',
               type: 'warning',
            });
            history.push('/');
         }
      } catch (error) {
         notification({
            message: errorFormmatter(error),
            type: 'warning',
         });
      }
   };

   const joinShow = async (channel) => {
      try {
         if (memberAllowedToJoinRef.current?.kickOut === true) {
            notification({
               title: 'Broadcasting',
               message: 'You have been kick out from the streaming',
               type: 'warning',
            });

            if (isJoin) {
               await endBroadcast();
               setIsModelOnline(false);
               setOpenUnAvailable(true);
               setIsJoin(false);
               dispatch(
                  updateIsOnPrivateCall({
                     isOnPrivateCall: false,
                  })
               );
            }

            history.push('/');
         }

         if (!isJoin) {
            const token = await getToken(
               `audience-${user.clientId}`,
               liveBroadcast?.isOnPrivateCall ? privateLink : link,
               'audience'
            );
            const isJoinedChannel = await joinChatChannel(
               channel,
               `audience-${user.clientId}`,
               token
            );
            const isDone = await initStreaming(
               `audience-${user.clientId}`,
               liveBroadcast?.isOnPrivateCall ? privateLink : link,
               'audience',
               token
            );

            if (isDone && isJoinedChannel) {
               await joinBroadCast(channel);
               setIsJoin(true);
               isJoinRef.current = true;
               handleGetConfig();
               await handleMemberInitialPayment();
            } else {
               //   notification({
               //     title: 'Broadcasting',
               //     type: 'warning',
               //     message: 'Error occurred while joining the broadcast',
               //   });
            }
         }
      } catch (error) {}
   };

   const checkMemberAllowedToJoin = async () => {
      try {
         const response = await getStreamMemberDisabled({
            memberId: user.clientId,
            modelId: modelId,
         });
         setMemberAllowedToJoin((prev) => {
            return {
               ...prev,
               ...response,
            };
         });
         memberAllowedToJoinRef.current = {
            ...memberAllowedToJoinRef.current,
            ...response,
         };
      } catch (error) {
         setMemberAllowedToJoin({});
      }
   };

   const joinBroadCast = async (channel) => {
      try {
         let localTrack = await createLocalRtcTrack();
         const isHostModelOnline = await checkHostModelOnline(
            liveBroadcast?.isOnPrivateCall ? privateLink : link
         );

         if (isHostModelOnline) {
            setIsModelOnline(true);
            isModelOnlineRef.current = true;
            setOpenUnAvailable(false);
         } else {
            setIsModelOnline(false);
            isModelOnlineRef.current = false;
            setOpenUnAvailable(true);
         }

         await registerRtmMessagingEvent('presence', handleMemberJoinedMessagingEvent);

         await registerRtmMessagingEvent('message', handleChannelMessage);
         await updateRtcMemberDetails(
            [
               {
                  key: 'id',
                  value: `audience-${user.clientId}` ?? '',
                  revision: -1,
               },
               {
                  key: 'email',
                  value: user.email ?? '',
                  revision: -1,
               },
               {
                  revision: -1,
                  key: 'profileUrl',
                  value:
                     memberProfile?.profilePicUrl ??
                     'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfIG1adz2cC3T6BaBzktqNU14KdRP0psfugw&usqp=CAU',
               },
               {
                  key: 'fullName',
                  value: user?.fullName ?? '',
                  revision: -1,
               },
            ],
            `audience-${user.clientId}`
         );

         await registerRtcEvent('user-published', handleUserPublish);
         await registerRtcEvent('user-unpublished', handleUserUnPublish);
         await registerRtcEvent('user-left', handleUserLeft);

         await micChangeDevice(localTrack[0]);
         await camChangeDevice(localTrack[1]);

         localTrack[1]?.setEnabled(false);
         localTrack[1]?.stop(`user-audience-${user.clientId}`);

         localTrack[0]?.setEnabled(false);
         localTrack[0]?.stop(`user-audience-${user.clientId}`);

         await addLocalUserToView(localTrack);
         dispatch(resetStreamingMsgs());
         const msgPayload = {
            type: 'bot',
            text: `Welcome to the broadcast ${user?.fullName}`,
            author: '🤖 Bot',
            messageId: `message-${uuidv4()}`,
         };
         await sendMessage(
            liveBroadcast?.isOnPrivateCall ? privateLink : link,
            msgPayload,
            messageContentRef,
            null
         );
         //await persistMessage(msgPayload);
         setLocalTracks(() => [...localTrack]);
         await updateMemberBroadcastStatus();
         const config =
            (await getChannelConfigs(liveBroadcast?.isOnPrivateCall ? privateLink : link)) ?? {};
         const { metadata } = config;
         if (metadata) {
            setCurrentChannelRoomNameAndTopic((prev) => {
               return {
                  ...prev,
                  channelName: metadata?.channelName?.value,
                  topic: metadata?.topic?.value,
               };
            });
         }
         window.addEventListener('beforeunload', () => {
            try {
               endBroadcast();
            } catch (error) {}
         });
      } catch (error) {
         //   notification({
         //     title: 'Broadcasting',
         //     type: 'warning',
         //     message: 'Error occurred while joining the broadcast',
         //   });
      }
   };

   const sendTip = async (payload) => {
      try {
         if (generalSettings?.current?.allowTips === false) {
            notification({
               title: 'Broadcasting',
               message: 'Tip action is not allowed',
               type: 'warning',
            });
            return;
         }
         const data = new FormData();
         data.append('description', payload?.message);
         data.append('credit', payload?.amount);
         data.append('memberId', user.clientId);
         data.append('registeredId', modelId ?? '');

         await sendTipFromMember(data);
         const msgPayload = {
            type: 'tip',
            text: `${user?.fullName} tipped ${payload?.amount} (${payload?.tip})`,
            optionalMessage: payload?.message,
            author: `${user?.fullName}`,
            messageId: `message-${uuidv4()}`,
         };
         await sendMessage(
            liveBroadcast?.isOnPrivateCall ? privateLink : link,
            msgPayload,
            messageContentRef,
            null
         );
         //await persistMessage(msgPayload);
      } catch (error) {
         notification({
            title: 'Broadcasting',
            type: 'danger',
            message: errorFormmatter(error),
         });
      }
   };

   const endBroadcast = async () => {
      try {
         const msgPayload = {
            type: 'bot',
            text: `${user?.fullName} has left the broadcast`,
            author: `🤖 Bot`,
            messageId: `message-${uuidv4()}`,
         };
         await sendMessage(
            liveBroadcast?.isOnPrivateCall ? privateLink : link,
            msgPayload,
            messageContentRef,
            null
         );
         //await persistMessage(msgPayload);
         leaveStreamingChannel(localTracks, `audience-${user.clientId}`).then(async () => {
            setIsJoin(false);
            isJoinRef.current = false;
            setCurrentTab(0);
            dispatch(resetStreamingMsgs());
            const hostPreview = document.querySelector('#liveBroadCast');
            if (hostPreview !== null) {
               resetModelRemoteView(hostPreview);
            }
         });
         if (liveBroadcast?.isOnPrivateCall) {
            dispatch(
               updateIsOnPrivateCall({
                  isOnPrivateCall: false,
               })
            );
         }
         await updateMemberBroadcastStatus();
      } catch (error) {}
   };

   const handleGetSetting = async () => {
      try {
         const response = await getStreamingConfig({
            modelId,
         });

         generalSettings.current = {
            ...response,
         };
      } catch (error) {}
   };

   const getModelDetail = async () => {
      try {
         const response = await getModelDetails({
            modelId: modelId ?? '',
         });
         setBroadcastModelDetail(response);
      } catch (error) {
         setBroadcastModelDetail(null);
      }
   };

   const getProfile = async () => {
      try {
         const response = await getMemberProfile(user?.clientId);
         setMemberProfile(response);
      } catch (error) {
         setMemberProfile({});
      }
   };

   const handleFullScreen = () => {
      const isFullscreen = document.fullscreenElement;

      if (!isFullscreen) {
         fullScreenRef?.current.requestFullscreen();
         setIsFullScreen(true);
      } else {
         document.exitFullscreen();
         setIsFullScreen(false);
      }
   };

   const checkUserMonthlySub = async () => {
      try {
         if (isModelOnlineRef.current === true) {
            const response = await getMemberMonthlySub(user.clientId);

            if (response && response?.isPaid === false) {
               await endBroadcast();
               notification({
                  title: 'Broadcasting',
                  type: 'warning',
                  message: 'You do not have monthly subscription to continue with model broadcast',
               });
               history.push('/');
            }
         }
      } catch (error) {
         notification({
            title: 'Broadcasting',
            type: 'warning',
            message: errorFormmatter(error),
         });
      }
   };

   useEffect(() => {
      getModelDetail().then(() => {
         if (broadcastModelDetail !== null) {
            checkMemberAllowedToJoin().then(() => {
               joinShow(liveBroadcast?.isOnPrivateCall ? privateLink : link);
            });
         }
      });

      window.addEventListener('fullscreenchange', (e) => {
         if (document.fullscreenElement === null) {
            setIsFullScreen(false);
         }
      });

      handleLiveMessages();
      getAllStreamingTipFromMember();
      getAllStreamingGoals();
      handleGetSetting();

      const getStreamingTipIntervalId = setInterval(async () => {
         try {
            handleGetSetting();
            getAllStreamingTipFromMember();

            checkMemberAllowedToJoin();
            checkHostModelOnline(linkRef.current);
         } catch (error) {}
      }, 10000);

      const getStreamingGoalIntervalId = setInterval(() => {
         getAllStreamingGoals();
      }, 5000);

      return () => {
         try {
            endBroadcast();
            clearInterval(getStreamingTipIntervalId);
            clearInterval(getStreamingGoalIntervalId);
         } catch (error) {}
      };
   }, [modelId]);

   useEffect(() => {
      const checkUserMonthlySubTimer = setInterval(() => {
         checkUserMonthlySub();
      }, 5 * 60 * 1000);

      getModelDetail().then(() => {
         if (broadcastModelDetail !== null) {
            checkMemberAllowedToJoin().then(() => {
               joinShow(liveBroadcast?.isOnPrivateCall ? privateLink : link);
            });
         }
      });

      window.addEventListener('fullscreenchange', (e) => {
         if (document.fullscreenElement === null) {
            setIsFullScreen(false);
         }
      });

      handleLiveMessages();
      getAllStreamingTipFromMember();
      getAllStreamingGoals();
      handleGetSetting();

      // const getStreamingTipIntervalId = setInterval(async () => {
      //    try {
      //       handleGetSetting();
      //       getAllStreamingTipFromMember();

      //       checkMemberAllowedToJoin();
      //       checkHostModelOnline(linkRef.current);
      //    } catch (error) {}
      // }, 10000);

      // const getStreamingGoalIntervalId = setInterval(() => {
      //    getAllStreamingGoals();
      // }, 5000);

      return () => {
         try {
            endBroadcast();

            // clearInterval(getStreamingTipIntervalId);
            clearInterval(getStreamingGoalIntervalId);
            clearInterval(checkUserMonthlySubTimer);
         } catch (error) {}
      };
   }, []);

   useEffect(() => {
      try {
         if (broadcastModelDetail !== null) {
            checkMemberAllowedToJoin();
            joinShow(liveBroadcast?.isOnPrivateCall ? privateLink : link);
         }
      } catch (error) {}
   }, [broadcastModelDetail]);

   useEffect(() => {
      try {
         handleLiveMessages();
      } catch (error) {}
   }, [currentTab, liveMessages?.general, liveMessages?.private]);

   useEffect(() => {
      if (linkRef.current === privateLink) {
         joinPrivateCall();
      }
   }, [linkRef.current]);

   useEffect(() => {
      if (!liveBroadcast.isOnPrivateCall) {
         if (privateRequestTimeOutId) {
            clearTimeout(privateRequestTimeOutId);
            linkRef.current = link;
         }
      }
   }, [liveBroadcast?.isOnPrivateCall]);

   useEffect(() => {
      getProfile();
   }, [user?.clientId]);

   return (
      <main
         ref={fullScreenRef}
         className={classNames(styles.container, isFullScreen && styles.fullScreen, 'mb-[10rem]')}
      >
         <PrivateRequestModal
            startPrivateCall={() => {
               startPrivateCall();
            }}
            openPrivateRequestConfirmation={openPrivateRequestConfirmation}
            senderUser={privateRequestUser}
            handleEnablePrivateRequest={() => {
               setOpenPrivateRequestModal(true);
            }}
            open={openPrivateRequestModal}
            handleClose={() => {
               setOpenPrivateRequestModal(false);
            }}
         />
         <SendTipModal
            handleSendTip={sendTip}
            open={openSendTipModal}
            handleClose={() => {
               setOpenSendTipModal(false);
            }}
         />
         {!openPrivateRequestConfirmation && (
            <UnavailableModal
               isModelOnline={isModelOnline}
               sendOfflineMessage={sendOfflineMessage}
               broadcastModelDetail={broadcastModelDetail}
               isOnPrivateCall={liveBroadcast?.isOnPrivateCall}
               handleBookPrivateRequest={liveBroadcast?.isOnPrivateCall}
               isLoading={sendPrivateChatMutation.isLoading}
               open={openUnAvailable}
               handleClose={() => {
                  setOpenUnAvailable(false);
               }}
            />
         )}
         <section className={isFullScreen && styles.liveBroadCastWrapperFullScreen}>
            <div
               className={classNames(
                  styles.liveBroadCast,
                  isModelOnline ? styles.online : styles.offline,
                  isFullScreen && styles.liveBroadCastFullScreen,
                  'relative'
               )}
               ref={broadcasterRef}
               id='liveBroadCast'
            >
               {isJoin === false && (
                  <div className={styles.liveBroadCastInnerContainer}>
                     <Spinner width='50' height='50' />
                  </div>
               )}

               {(isModelOnline === false || isVideoMutedRef?.current === false) && (
                  <div className={styles.unavailablePreviewContainer}>
                     {broadcastModelDetail?.profilePicUrl && (
                        <img
                           src={broadcastModelDetail?.profilePicUrl}
                           className={styles.modelProfile}
                        />
                     )}
                  </div>
               )}
            </div>
            <section className={styles.topControllerContainer}>
               <section className={styles.topActionButton}>
                  {isFullScreen && (
                     <Button
                        onClick={() => {
                           handleBookPrivateRequest();
                        }}
                        text={liveBroadcast?.isOnPrivateCall ? 'End show' : 'Start show'}
                        className={styles.fullScreenStartPrivateShowButton}
                     />
                  )}
                  <Button
                     text={'Send Tip'}
                     className={styles.sendTipButton}
                     onClick={() => {
                        setOpenSendTipModal(true);
                     }}
                  />
                  <Button
                     icon={TfiMenuAlt}
                     text={'Tip Menu'}
                     className={styles.tipMenuButton}
                     onClick={() => setCurrentTab(2)}
                  />
                  <Button
                     icon={VibratorIcon}
                     text={'Interactive Toy tip'}
                     className={styles.toyButton}
                     onClick={() => {
                        setOpenSendTipModal(true);
                     }}
                  />
               </section>
               <section className={styles.topInnerControlContainer}>
                  <span className={styles.topController} onClick={muteAudio}>
                     {isAudioMutedRef?.current && <span className={styles.topMuteCrossIcon}></span>}
                     <CiVolumeHigh className={styles.icon} />
                  </span>
                  <span className={styles.topController} onClick={muteVideo}>
                     {isVideoMutedRef.current && <span className={styles.topMuteCrossIcon}></span>}
                     <CamIcon className={classNames(styles.icon, 'w-[25px] h-[25px]')} />
                  </span>
                  <RiFullscreenFill
                     className={styles.icon}
                     onClick={() => {
                        handleFullScreen();
                        // setIsFullScreen(!isFullScreen);
                     }}
                  />
               </section>
            </section>
            {!isFullScreen && (
               <section className={styles.goalContainer}>
                  <h1 className={styles.goalTitle}>
                     Goal :{' '}
                     <div className={styles.progressWrapperContainer}>
                        <div className={styles.progressContainer}>
                           <div
                              className={styles.progressInnerContainer}
                              style={{
                                 width: `${
                                    isModelOnline
                                       ? currentGoalDetails.percentageOfTotalGoalEarned
                                       : 0
                                 }%`,
                              }}
                           ></div>
                        </div>
                        <span className={styles.progressPercentage}>
                           {isModelOnline ? currentGoalDetails.percentageOfTotalGoalEarned : 0}%
                        </span>
                     </div>{' '}
                     <span className={styles.credit}>
                        {isModelOnline ? currentGoalDetails.totalEarned : 0} /{' '}
                        {isModelOnline ? currentGoalDetails.totalCredit : 0} credits{' '}
                     </span>{' '}
                     <span className={styles.creditLeft}>
                        ({isModelOnline ? currentGoalDetails.leftCreditToEarn : 0} credits left)
                     </span>
                  </h1>
                  <h1 className={styles.goalSubTitle}>
                     This is a really long text stating the goal and having a progress attached
                  </h1>
               </section>
            )}
         </section>
         <section className={isFullScreen && styles.chatWrapperFullScreen}>
            <section
               className={classNames(
                  styles.chatRoomContainer,
                  isFullScreen && styles.chatRoomFullScreen
               )}
            >
               <section className={styles.chatRoomHeader}>
                  <h1 className={styles.modelName}>{broadcastModelDetail?.firstName ?? ''}</h1>
                  <h2 className={styles.modelOnlineIndicator}>
                     {' '}
                     <span
                        className={classNames(
                           styles.modelOnlineSpot,
                           isModelOnline
                              ? styles.modelOnlineSpotOnline
                              : styles.modelOnlineSpotOffline
                        )}
                     ></span>
                     {liveBroadcast?.isOnPrivateCall === false ? (
                        <>{currentChannelRoomNameAndTopic?.channelName ?? 'General chat'}</>
                     ) : (
                        <>{currentChannelRoomNameAndTopic?.channelName ?? 'Private chat'}</>
                     )}
                  </h2>
                  <h3 className={styles.broadcastTitle}>
                     Topic : {currentChannelRoomNameAndTopic?.topic ?? '####'}
                  </h3>
               </section>

               <Tab
                  panelClass={styles.tabContainer}
                  containerClass={isFullScreen && styles.chatRoomInnerFullScreen}
                  tabClass={styles.navList}
                  position='left'
                  onIndexChange={(tab) => {
                     setCurrentTab(tab);
                  }}
                  defaultKeyIndex={currentTab}
                  items={[
                     {
                        key: 'General',
                        children: (
                           <section
                              key={0}
                              className={classNames(
                                 styles.chatRoomMessageContainer,
                                 isFullScreen && styles.chatRoomMessageContainerFullScreen
                              )}
                           >
                              <div className={styles.table}>
                                 <div
                                    className={styles.chatRoomMessageContent}
                                    ref={messageContentRef}
                                 ></div>
                              </div>
                           </section>
                        ),
                     },
                     {
                        key: `Private`,
                        children: (
                           <section
                              key={0}
                              className={classNames(
                                 styles.chatRoomMessageContainer,
                                 isFullScreen && styles.chatRoomMessageContainerFullScreen
                              )}
                           >
                              <div className={styles.table}>
                                 <div
                                    className={styles.chatRoomMessageContent}
                                    ref={messageContentRef}
                                 ></div>
                              </div>
                           </section>
                        ),
                     },

                     {
                        key: `Tip Menu`,
                        children: (
                           <TipMenu
                              rankingMembers={highestTip}
                              modelId={modelId}
                              isFullScreen={isFullScreen}
                           />
                        ),
                     },
                  ]}
               />

               <section
                  className={classNames(
                     styles.chatRoomFooter,
                     isFullScreen && styles.chatRoomFooterFullScreen
                  )}
               >
                  <form className={styles.chatRoomInputContainer} onSubmit={sendChat}>
                     <input
                        value={message ? message : ''}
                        onChange={onMessageChange}
                        type={'text'}
                        className={styles.chatRoomInput}
                        placeholder='Enter a message...'
                     />
                     {isSending ? (
                        <Spinner />
                     ) : (
                        <SendIcon onClick={sendChat} className={styles.sendIcon} />
                     )}
                  </form>
                  {/* {isFullScreen && (
              <section className={styles.streamControl}>
                <div className={styles.streamController}>
                  <SnapShotIcon />
                  <span
                    className={styles.streamInnerController}
                    onClick={muteAudio}
                  >
                    {isAudioMuted && (
                      <span className={styles.muteCrossIcon}></span>
                    )}
                    <MicIcon />
                  </span>
                  <span
                    className={styles.streamInnerController}
                    onClick={muteVideo}
                  >
                    {isVideoMuted && (
                      <span className={styles.muteCrossIcon}></span>
                    )}
                    <CamIcon />
                  </span>

                  <SettingIcon />
                </div>
              </section>
            )} */}
               </section>
            </section>
            <section
               className={classNames(
                  styles.streamControl,
                  isFullScreen && styles.streamControlFullScreen
               )}
            >
               {/* <div className={styles.streamController}>
            <SnapShotIcon />
            <span className={styles.streamInnerController} onClick={muteAudio}>
              {isAudioMuted && <span className={styles.muteCrossIcon}></span>}
              <MicIcon />
            </span>
            <span className={styles.streamInnerController} onClick={muteVideo}>
              {isVideoMuted && <span className={styles.muteCrossIcon}></span>}
              <CamIcon />
            </span>

            <SettingIcon />
          </div> */}
               <Button
                  onClick={() => {
                     if (isModelOnline) {
                        handleBookPrivateRequest();
                     } else {
                        sendOfflineMessage();
                     }
                  }}
                  text={
                     //   liveBroadcast?.isOnPrivateCall
                     //     ? 'End A Private Show'
                     //     : 'Book A Private Show'
                     liveBroadcast?.isOnPrivateCall && isModelOnline
                        ? 'End A Private Show'
                        : sendPrivateChatMutation.isLoading
                        ? 'Sending.. Chat'
                        : 'Book A Private Show'
                  }
                  className={styles.startPrivateShowButton}
               />
            </section>
         </section>
      </main>
   );
};

export default MemberViewLiveStreaming;
