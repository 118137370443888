import Popup from 'reactjs-popup';
import styles from './styles.module.scss';
import Button from '../../../custom/btn/Btn';
import { AiOutlineClose } from 'react-icons/ai';
import { useState } from 'react';
import notification from '../../../utils/notification';
import CryptoJS from 'crypto-js';

const TopUpCreditModal = ({ getCreditPaymentUrl, open, email, handleClose }) => {
	const [amount, setAmount] = useState(0);

	const Period = process.env.REACT_APP_PERIOD;
	const SALT_KEY = process.env.REACT_APP_SALT_KEY;
	const SUBACCOUNT = process.env.REACT_APP_SUBACCOUNT;
	const CURRENCY_CODE = process.env.REACT_APP_CURRENCY_CODE;

	const generateFormDigest = (initialPrice, initialPeriod, currencyCode) => {
		const inputString = `${parseFloat(initialPrice).toFixed(2)}${initialPeriod}${currencyCode}${SALT_KEY}`;
		return CryptoJS.MD5(inputString).toString();
	};

	const handleRedirect = () => {
		const formDigest = generateFormDigest(amount, Period, CURRENCY_CODE);
		const redirectUrl = `${getCreditPaymentUrl}?clientSubacc=${SUBACCOUNT}&initialPrice=${amount}&initialPeriod=${Period}&currencyCode=${CURRENCY_CODE}&formDigest=${formDigest}&email=${email}`;
		window.location.href = redirectUrl;
	};

	const handleInputChange = event => {
		const value = event.target.value;
		setAmount(value);
	};

	const topUpCredit = () => {
		if (parseInt(amount) < 1) {
			return notification({
				title: 'Broadcasting',
				type: 'warning',
				message: 'Top up credit amount must be greater than 1$ ',
			});
		}

		handleRedirect();
	};

	return (
		<Popup open={open}>
			<main className={styles.container}>
				<h1 className={styles.title}>
					Top up Credit
					<AiOutlineClose className={styles.icon} onClick={() => handleClose(false)} />
				</h1>

				<section className={styles.inputContainer}>
					<label className={styles.label}>Enter amount</label>
					<input
						onChange={handleInputChange}
						name='amount'
						type='number'
						className={styles.input}
						placeholder='enter credit amount'
					/>
				</section>

				<Button onClick={topUpCredit} text={'Top Up Credit'} className={styles.sendTipButton} />
			</main>
		</Popup>
	);
};

export default TopUpCreditModal;
